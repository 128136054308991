import React, { Suspense } from 'react'
import { Spin } from 'antd'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import Dashboard from './dashboard'
import Orders from './orders'
import Products from './products'
import Posts from './posts'
import Stocks from './stocks'
import Calendar from './calendar'
import Tickets from './tickets'
import Invoices from './invoices'
import Profile from './profile'
import Users from './users'

import withAdminLayout from '../../layout/withAdminLayout'

const Admin = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path={path} component={Dashboard} />
        <Route path={`${path}/orders`} component={Orders} />
        <Route path={`${path}/products`} component={Products} />
        <Route path={`${path}/posts`} component={Posts} />
        <Route path={`${path}/supply`} component={Stocks} />
        <Route path={`${path}/calendar`} component={Calendar} />
        <Route path={`${path}/tickets`} component={Tickets} />
        <Route path={`${path}/invoices`} component={Invoices} />
        <Route path={`${path}/profile`} component={Profile} />
        <Route path={`${path}/users`} component={Users} />
      </Suspense>
    </Switch>
  )
}

export default withAdminLayout(Admin)
