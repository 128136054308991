import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import AuthLayout from '../container/profile/authentication'
import Login from '../container/profile/authentication/overview/SignIn'
import ForgotPass from '../container/profile/authentication/overview/ForgotPassword'
import ResetPassword from '../container/profile/authentication/overview/ResetPassword'

const FrontendRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route path="/forgot-password" component={ForgotPass} />
      <Route path="/reset-password" component={ResetPassword} />
      <Redirect to="/" />
    </Switch>
  )
}

export default AuthLayout(<FrontendRoutes />)
