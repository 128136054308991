import React, { lazy } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

const Stocks = lazy(() => import('../../container/supply/stocks'))
const Items = lazy(() => import('../../container/supply/items'))
const OrderItemGroups = lazy(() => import('../../container/supply/orderItemGroups'))
const Barcode = lazy(() => import('../../container/supply/barcode'))

const StocksRoutes = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${path}/stocks`} component={Stocks} />
      <Route exact path={`${path}/items`} component={Items} />
      <Route exact path={`${path}/item-groups`} component={OrderItemGroups} />
      <Route exact path={`${path}/barcode`} component={Barcode} />
    </Switch>
  )
}

export default StocksRoutes
