import React from 'react'
import { hot } from 'react-hot-loader/root'
import { Provider, useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import store, { RootState } from './redux/store'
import Admin from './routes/admin'
import Auth from './routes/auth'
import './static/css/style.css'
import config from './config/config'
import ProtectedRoute from './components/utilities/protectedRoute'

const { theme } = config

const ProviderConfig = () => {
  const { isLoggedIn } = useSelector<RootState, any>(state => {
    return {
      isLoggedIn: state.auth.token,
    }
  })

  return (
    <ConfigProvider direction={'ltr'}>
      <ThemeProvider theme={{ ...theme }}>
        <Router basename={process.env.PUBLIC_URL}>
          <Switch>
            {!isLoggedIn ? <Route path="/" component={Auth} /> : <ProtectedRoute path="/admin" component={Admin} />}
            {isLoggedIn && <Redirect to="/admin" />}
          </Switch>
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  )
}

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  )
}

export default hot(App)
