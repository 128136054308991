import React, { lazy } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

const Profile = lazy(() => import('../../container/profile/profile'))

const ProfileRoutes = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={path} component={Profile} />
    </Switch>
  )
}

export default ProfileRoutes
