import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import client from './graphql/server'
import { ApolloProvider } from '@apollo/client'
import { notification } from 'antd'
import moment from 'moment'
import 'moment/locale/hu'

moment.updateLocale('hu', {
  week: {
    dow: 1,
    doy: 1,
  },
})

notification.config({ placement: 'bottomRight' })

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,

  document.getElementById('root'),
)

serviceWorker.unregister()
