import Cookies from 'js-cookie'
import { AppDispatch } from '../store'
import actions from './actions'

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr } = actions

const login = (token: string, storeCookie: boolean = true) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(loginBegin())
      Cookies.set('accessToken', token)
      if (storeCookie) {
        localStorage.setItem('OCAccessToken', token)
      }
      return dispatch(loginSuccess(token))
    } catch (err) {
      dispatch(loginErr(err))
    }
  }
}

const logOut = () => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(logoutBegin())
      Cookies.remove('accessToken')
      localStorage.removeItem('OCAccessToken')
      dispatch(logoutSuccess())
    } catch (err) {
      dispatch(logoutErr(err))
    }
  }
}

export { login, logOut }
