import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Form, Input, Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AuthWrapper } from './style'
import { login as loginAction } from '../../../../redux/authentication/actionCreator'
import { Checkbox } from '../../../../components/checkbox/checkbox'
import Heading from '../../../../components/heading/heading'
import { useLoginMutation } from '../../../../graphql/generated/graphql'
import { RootState } from '../../../../redux/store'
import Spacing from '../../../../components/OrderChannel/Misc/Spacing'

const SignIn = () => {
  const dispatch = useDispatch()

  const isLoading = useSelector<RootState>(state => state.auth.loading)
  const [form] = Form.useForm()
  const [state, setState] = useState({
    checked: false,
  })
  const [loading, setLoading] = useState(false)
  const [formErrors, setFormErrors] = useState({ login: '', password: '' })

  const [login] = useLoginMutation()

  const handleSubmit = async () => {
    if (loading) return
    setLoading(true)
    const email = form.getFieldValue('email')
    const password = form.getFieldValue('password')

    const { data, errors } = await login({
      variables: { input: { email, password } },
      refetchQueries: 'active',
    })

    setLoading(false)
    if (errors?.length) {
      const [error] = errors
      return setFormErrors({ ...formErrors, login: error.message })
    } else {
      const { token } = data!.login
      dispatch(loginAction(token, state.checked))
    }
  }

  const onChange = (checked: boolean) => {
    setState({ ...state, checked })
  }

  return (
    <AuthWrapper>
      <div className="auth-contents">
        <Form name="login" form={form} onFinish={handleSubmit} layout="vertical">
          <Heading as="h3">Bejelentkezés</Heading>
          <Form.Item
            validateStatus={formErrors.login ? 'error' : undefined}
            help={formErrors.login}
            name="email"
            rules={[{ message: 'Adj meg egy emailt!', required: true }]}
            label="Email"
          >
            <Input placeholder="name@example.com" />
          </Form.Item>
          <Form.Item name="password" label="Jelszó">
            <Input.Password placeholder="*******" />
          </Form.Item>
          <div className="auth-form-action">
            <Checkbox checked={state.checked} onChange={onChange}>
              Bejelentkezve maradok
            </Checkbox>
            <NavLink className="forgot-pass-link" to="/forgot-password">
              Elfelejtetted a jelszavad?
            </NavLink>
          </div>

          <Spacing y={10} />
          <Form.Item>
            <Button className="btn-signin" htmlType="submit" type="primary" size="large">
              {isLoading ? 'Bejelentkezés...' : 'Bejelentkezés'}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </AuthWrapper>
  )
}

export default SignIn
