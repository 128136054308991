import React, { lazy } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

const Products = lazy(() => import('../../container/products'))
const Details = lazy(() => import('../../container/products/details'))
const Import = lazy(() => import('../../container/products/import'))
const Suppliers = lazy(() => import('../../container/products/suppliers'))
const EANFilter = lazy(() => import('../../container/products/eanFilter'))

const ProductsRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path} component={Products} />
      <Route exact path={`${path}/import`} component={Import} />
      <Route exact path={`${path}/ean`} component={EANFilter} />
      <Route exact path={`${path}/suppliers`} component={Suppliers} />
      <Route path={`${path}/:productId`} component={Details} />
    </Switch>
  )
}

export default ProductsRoutes
