import React, { lazy } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

const Users = lazy(() => import('../../container/users/users'))

const UsersRoutes = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={path} component={Users} />
    </Switch>
  )
}

export default UsersRoutes
