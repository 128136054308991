import React, { useState } from 'react'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import { Row, Col, Form, Input, Button, notification } from 'antd'
import { AuthWrapper } from './style'
import Heading from '../../../../components/heading/heading'
import { useForgotPasswordMutation } from '../../../../graphql/generated/graphql'
import { CheckIcon } from '../../../../components/OrderChannel/Icons'
import { theme } from '../../../../config/theme/themeVariables'

const ForgotPassword = () => {
  const [state, setState] = useState({
    values: null,
    success: false,
  })

  const [forgotPassword] = useForgotPasswordMutation()

  const handleSubmit = async ({ email }) => {
    const { errors } = await forgotPassword({ variables: { input: { email } } })
    if (errors?.length) {
      notification.error({ message: `Hiba: ${errors[0].message}` })
    } else {
      setState({ ...state, success: true })
    }
  }

  return (
    <AuthWrapper>
      <div className="auth-contents">
        {state.success ? (
          <Row justify="center" align="middle">
            <Col>
              <CheckIcon width={64} height={64} fill={theme['success-color']} />
              <Heading as="h3">A visszaállításhoz szükséges infókat elküldtük!</Heading>
            </Col>
          </Row>
        ) : (
          <Form name="forgotPass" onFinish={handleSubmit} layout="vertical">
            <Heading as="h3">Elhagytad a jelszavad?</Heading>
            <p className="forgot-text">Add meg az email címed, és elküldjük rá a teendőket!</p>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Add meg az emailed!', type: 'email' }]}
            >
              <Input placeholder="name@example.com" />
            </Form.Item>
            <Form.Item>
              <Button className="btn-reset" htmlType="submit" type="primary" size="large">
                Új jelszó kérése
              </Button>
            </Form.Item>
            <p className="return-text">
              Vissza a <NavLink to="/">bejelentkezéshez</NavLink>
            </p>
          </Form>
        )}
      </div>
    </AuthWrapper>
  )
}

export default ForgotPassword
