import Cookies from 'js-cookie'
import { Reducer } from 'redux'
import { Maybe, User } from '../../graphql/generated/graphql'
import { AuthActionFn } from './actions'

interface AuthState {
  loading: boolean
  user: Maybe<User>
  token?: string
  error?: string
}

const initState: AuthState = {
  token: localStorage.getItem('OCAccessToken') ?? Cookies.get('accessToken'),
  user: null,
  loading: false,
}

const AuthReducer: Reducer<AuthState, ReturnType<AuthActionFn>> = (state = initState, action): AuthState => {
  const { type, data, error } = action
  switch (type) {
    case 'LOGIN_BEGIN':
      return {
        ...state,
        loading: true,
      }
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        token: data,
        loading: false,
      }
    case 'LOGIN_ERR':
      return {
        ...state,
        error,
        loading: false,
      }
    case 'LOGOUT_BEGIN':
      return {
        ...state,
        loading: true,
      }
    case 'LOGOUT_SUCCESS':
      return {
        ...state,
        token: data,
        loading: false,
      }
    case 'LOGOUT_ERR':
      return {
        ...state,
        error,
        loading: false,
      }
    default:
      return state
  }
}

export default AuthReducer
