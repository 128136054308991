import React, { useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { Form, Input, Button, notification } from 'antd'
import { AuthWrapper } from './style'
import Heading from '../../../../components/heading/heading'
import { ResetPasswordInput, useResetPasswordMutation } from '../../../../graphql/generated/graphql'

type InputData = Pick<ResetPasswordInput, 'email' | 'password' | 'passwordConfirmation'>

const ResetPassword: React.FC<RouteComponentProps> = ({ location: { search } }) => {
  const [state, setState] = useState<InputData>({
    email: '',
    password: '',
    passwordConfirmation: '',
  })
  const [loading, setLoading] = useState(false)
  const [resetPassword] = useResetPasswordMutation()
  const history = useHistory()

  const handleSubmit = async ({ email, password, passwordConfirmation }: InputData) => {
    if (loading) return
    setLoading(true)
    const token = new URLSearchParams(search).get('token')

    if (!token) return

    const { errors } = await resetPassword({
      variables: { input: { email, password, passwordConfirmation, token } },
    })
    if (errors?.length) {
      notification.error({ message: `Hiba: ${errors[0].message}` })
    } else {
      history.push('/')
    }
    setLoading(false)
    setState({ ...state, email, password, passwordConfirmation })
  }

  return (
    <AuthWrapper>
      <div className="auth-contents">
        <Form name="forgotPass" onFinish={handleSubmit} layout="vertical">
          <Heading as="h3">Új jelszó beállítása</Heading>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Add meg az emailed!', type: 'email' }]}
          >
            <Input placeholder="name@example.com" />
          </Form.Item>
          <Form.Item
            label="Új jelszó"
            name="password"
            rules={[{ required: true, message: 'Add meg az új jelszót!', type: 'string' }]}
          >
            <Input type="password" placeholder="*******" />
          </Form.Item>
          <Form.Item
            label="Új jelszó mégegyszer"
            name="passwordConfirmation"
            rules={[{ required: true, message: 'Add meg az új jelszót mégegyszer!', type: 'string' }]}
          >
            <Input type="password" placeholder="*******" />
          </Form.Item>
          <Form.Item>
            <Button className="btn-reset" htmlType="submit" type="primary" size="large">
              Jelszó mentése
            </Button>
          </Form.Item>
        </Form>
      </div>
    </AuthWrapper>
  )
}

export default ResetPassword
