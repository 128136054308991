import React from 'react'
import { Col } from 'antd'
import { Content } from './overview/style'
import Heading from '../../../components/heading/heading'
import Logo from '../../../components/OrderChannel/Logo/Logo'
import { NavLink } from 'react-router-dom'

const AuthLayout = (AuthContent: JSX.Element) => {
  return () => {
    return (
      <Col>
        <NavLink to="/">
          <Content style={{ textAlign: 'center' }}>
            <Logo width="40px" height="40px" />
            <br />
            <Heading as="h1">OrderChannel</Heading>
          </Content>
        </NavLink>
        {AuthContent}
      </Col>
    )
  }
}

export default AuthLayout
