import React, { lazy } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

const Orders = lazy(() => import('../../container/orders'))
const Details = lazy(() => import('../../container/orders/details'))

const OrdersRoutes = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={path} component={Orders} />
      <Route path={`${path}/:orderId`} component={Details} />
    </Switch>
  )
}

export default OrdersRoutes
