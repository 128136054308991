import React, { MouseEventHandler, useState } from 'react'
import { Menu } from 'antd'
import { NavLink, useRouteMatch } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react'
import {
  ChatIcon,
  CogwheelIcon,
  ConversationIcon,
  NewsIcon,
  SearchFileIcon,
  UsersIcon,
} from '../components/OrderChannel/Icons'
import { theme } from '../config/theme/themeVariables'

const menuIconProps = { width: '16px', fill: theme['light-color'] }

const { SubMenu } = Menu

interface Props {
  darkMode: boolean
  toggleCollapsed: MouseEventHandler
  events: any
}

interface MenuItem {
  title: string
  path: string
  key: string
  menuItems?: MenuItem[]
  icon?: React.ReactNode
}

const MenuItems: React.FC<Props> = ({ darkMode, toggleCollapsed, events }) => {
  const { path } = useRouteMatch()

  const pathName = window.location.pathname
  const pathArray = pathName.split(path)
  const mainPath = pathArray[1]
  const mainPathSplit = mainPath.split('/')

  const [openKeys, setOpenKeys] = useState([`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`])

  const onOpenChange = (keys: any[]) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys)
  }

  const onClick = (item: any) => {
    if (item.keyPath.length === 1) setOpenKeys([])
  }

  const menuItems: MenuItem[] = [
    { key: 'home', path: '', title: 'Főoldal', icon: <FeatherIcon icon="home" /> },
    { key: 'orders', path: '/orders', title: 'Rendelések', icon: <FeatherIcon icon="book" /> },
    {
      key: 'products',
      path: '/products',
      title: 'Termékek',
      icon: <SearchFileIcon {...menuIconProps} />,
      menuItems: [
        { key: 'edit-products', path: '', title: 'Termékek kezelése' },
        { key: 'import-products', path: '/import', title: 'Import' },
        { key: 'products-ean', path: '/ean', title: 'EAN kódok' },
        { key: 'suppliers', path: '/suppliers', title: 'Beszállítók' },
      ],
    },
    {
      key: 'supply',
      icon: <FeatherIcon icon="box" />,
      title: 'Beszerzés',
      path: '/supply',
      menuItems: [
        { key: 'stock', path: '/stocks', title: 'Raktárkészlet' },
        { key: 'supply-coming', path: '/items', title: 'Beszerzés alatt' },
        { key: 'supply-groups', path: '/item-groups', title: 'Rendelési állomány' },
        { key: 'barcode', path: '/barcode', title: 'Vonalkód olvasó' },
      ],
    },
    {
      key: 'invoices',
      icon: <NewsIcon {...menuIconProps} />,
      title: 'Számlák',
      path: '/invoices',
      menuItems: [
        { key: 'outgoing-invoices', path: '/outgoing', title: 'Kimenő' },
        { key: 'incoming-invoices', path: '/incoming', title: 'Bejövő' },
      ],
    },
    { icon: <FeatherIcon icon="calendar" />, key: 'calendar', path: '/calendar', title: 'Eseménynaptár' },
    { icon: <ChatIcon {...menuIconProps} />, key: 'tickets', path: '/tickets', title: 'Ticketek' },
    {
      icon: <ConversationIcon {...menuIconProps} />,
      key: 'posts',
      path: '/posts',
      title: 'Üzenőfal',
    },
    { icon: <UsersIcon {...menuIconProps} />, key: 'users', path: '/users', title: 'Felhasználók' },
    {
      icon: <CogwheelIcon {...menuIconProps} />,
      key: 'profile-settings',
      path: '/profile',
      title: 'Profil beállítások',
    },
  ]

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={'inline'}
      theme={darkMode ? 'dark' : 'light'}
      defaultSelectedKeys={[
        `${mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]}`,
      ]}
      defaultOpenKeys={[`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`]}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      {/* Order channel's menu */}
      {menuItems.map(item =>
        item.menuItems?.length ? (
          <SubMenu icon={item.icon} title={item.title} key={item.key}>
            {item.menuItems.map(subitem => (
              <Menu.Item key={subitem.key}>
                <NavLink style={{ marginLeft: 0 }} onClick={toggleCollapsed} to={`${path}${item.path}${subitem.path}`}>
                  {subitem.title}
                </NavLink>
              </Menu.Item>
            ))}
          </SubMenu>
        ) : (
          <Menu.Item
            icon={
              <NavLink style={{ width: 'unset', padding: 0, margin: 0 }} to={`${path}${item.path}`}>
                {item.icon}
              </NavLink>
            }
            key={item.key}
          >
            <NavLink onClick={toggleCollapsed} to={`${path}${item.path}`}>
              {item.title}
            </NavLink>
          </Menu.Item>
        ),
      )}
    </Menu>
  )
}

export default MenuItems
