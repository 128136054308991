import React, { lazy } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

const Calendar = lazy(() => import('../../container/calendar'))

const CalendarRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={path} component={Calendar} />
    </Switch>
  )
}

export default CalendarRoutes
