import { combineReducers } from 'redux'
import { readMessageReducer } from './message/reducers'
import { readNotificationReducer } from './notification/reducers'
import authReducer from './authentication/reducers'
import ChangeLayoutMode from './themeLayout/reducers'
import { userReducer, userGroupReducer } from './users/reducers'
import { headerSearchReducer } from './headerSearch/reducers'
import chartContentReducer from './chartContent/reducers'
import calendarReducer from './calendar/reducers'

const rootReducers = combineReducers({
  headerSearchData: headerSearchReducer,
  message: readMessageReducer,
  Calendar: calendarReducer,
  notification: readNotificationReducer,
  users: userReducer,
  userGroup: userGroupReducer,
  auth: authReducer,
  ChangeLayoutMode,
  chartContent: chartContentReducer,
})

export default rootReducers
