import React from 'react'
import styled from 'styled-components'

interface Props {
  x?: number
  y?: number
}

const Spacing: React.FC<Props> = ({ x = 0, y = 0 }) => {
  const SpacingStyle = styled.div`
    height: ${y}px;
    margin-left: ${x}px;
  `

  return <SpacingStyle></SpacingStyle>
}

export default Spacing
