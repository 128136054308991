import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  DateTimeTz: any;
  /**
   * Loose type that allows any value. Be careful when passing in large `Int` or `Float` literals,
   * as they may not be parsed correctly on the server side. Use `String` literals if you are
   * dealing with really large numbers to be on the safe side.
   */
  Mixed: any;
  Upload: any;
};
































export type AccessToken = {
  __typename?: 'AccessToken';
  token: Scalars['String'];
};

export type Address = {
  __typename?: 'Address';
  name?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type BulkUpdateOrderInput = {
  status?: Maybe<OrderStatus>;
};

export type BulkUpdateOrderItemInput = {
  status?: Maybe<ManualOrderItemStatus>;
};

export type BulkUpdateProductInput = {
  isDraft?: Maybe<Scalars['Boolean']>;
};

export type Chart = {
  __typename?: 'Chart';
  sum?: Maybe<Array<Stat>>;
  items?: Maybe<Array<DatedStat>>;
  deviation?: Maybe<Stat>;
};

export type Comment = {
  __typename?: 'Comment';
  id: Scalars['ID'];
  user: User;
  isLiked: Scalars['Boolean'];
  likes?: Maybe<Array<Like>>;
  body?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
  post: Post;
  createdAt: Scalars['DateTimeTz'];
};

export type CommentInput = {
  body?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['ID']>;
  postId: Scalars['ID'];
};

export type CreateEventInput = {
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  from: Scalars['DateTimeTz'];
  to: Scalars['DateTimeTz'];
  userClass?: Maybe<UserClass>;
};

export type CreateImportInput = {
  supplierId: Scalars['ID'];
  lang: Scalars['String'];
  marketplace: MarketplaceSlug;
};

export type CreateIncomingInvoiceInput = {
  invoiceNumber: Scalars['String'];
  supplierId: Scalars['ID'];
  orderItemGroupId?: Maybe<Scalars['ID']>;
  amount: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  status: InvoiceStatus;
  invoiceCreatedAt: Scalars['Date'];
};

export type CreateOrderItemGroupInput = {
  date: Scalars['Date'];
  orderItemIds: Array<Scalars['ID']>;
  supplierId: Scalars['ID'];
};

export type CreateStockInput = {
  ean: Scalars['String'];
  quantity: Scalars['Int'];
};

export type CreateSupplierInput = {
  name: Scalars['String'];
  url: Scalars['String'];
};

export type CreateUserInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  imageId?: Maybe<Scalars['ID']>;
  roles?: Maybe<Array<UserRole>>;
  classes?: Maybe<Array<UserClass>>;
};

export type Currency = {
  __typename?: 'Currency';
  /** Ez az azonosító pl: HUF */
  slug: Scalars['String'];
  /** Ez az előtag, pl: $ */
  prefix?: Maybe<Scalars['String']>;
  /** Ez az utótag, pl: Ft */
  suffix?: Maybe<Scalars['String']>;
};

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['ID'];
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};


export type DateRangeFilter = {
  from: Scalars['Date'];
  to: Scalars['Date'];
};



export type DatedStat = {
  __typename?: 'DatedStat';
  date: Scalars['Date'];
  values?: Maybe<Array<Stat>>;
  size: Scalars['ID'];
};

export type EanCode = {
  __typename?: 'EanCode';
  id: Scalars['ID'];
  ean: Scalars['String'];
  type: EanCodeType;
};

export type EanCodeFilter = {
  searchTerm?: Maybe<Scalars['String']>;
};

export type EanCodeInput = {
  ean: Scalars['String'];
  type: EanCodeType;
};

/** A paginated list of EanCode items. */
export type EanCodePaginator = {
  __typename?: 'EanCodePaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of EanCode items. */
  data: Array<EanCode>;
};

export enum EanCodeType {
  DisableUpload = 'DISABLE_UPLOAD'
}

export type Event = {
  __typename?: 'Event';
  id: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  from: Scalars['DateTimeTz'];
  to: Scalars['DateTimeTz'];
  userClass?: Maybe<UserClass>;
};

export type EventFilter = {
  dateRange: DateRangeFilter;
  userClass?: Maybe<UserClass>;
};

export type ForgotPasswordInput = {
  email: Scalars['String'];
};

export type ForgotPasswordResponse = {
  __typename?: 'ForgotPasswordResponse';
  status: ForgotPasswordStatus;
  message?: Maybe<Scalars['String']>;
};

export enum ForgotPasswordStatus {
  /** EMAIL_SENT */
  EmailSent = 'EMAIL_SENT'
}

export type GenerateFileForImportInput = {
  properties: Array<ImportPropertyInput>;
  priceMultiplier: Scalars['Float'];
  currencyMultiplier?: Maybe<Scalars['Float']>;
  vat: Scalars['Int'];
};

export type Image = {
  __typename?: 'Image';
  /** Max 10 MB, mimes: jpg,png,bmp */
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type Import = {
  __typename?: 'Import';
  id: Scalars['ID'];
  supplier?: Maybe<Supplier>;
  uploadFileUrl?: Maybe<Scalars['String']>;
  lang: Scalars['String'];
  status: ImportStatus;
  priceMultiplier: Scalars['Float'];
  currencyMultiplier: Scalars['Float'];
  vat: Scalars['Int'];
  marketplace: Marketplace;
  properties?: Maybe<Array<ImportProperty>>;
  filtered?: Maybe<Array<Scalars['String']>>;
  productCount?: Maybe<Scalars['Int']>;
  duplicationCount?: Maybe<Scalars['Int']>;
  updatedCount?: Maybe<Scalars['Int']>;
  eanMissingCount?: Maybe<Scalars['Int']>;
  deactivatedCount?: Maybe<Scalars['Int']>;
  newMissedProducts?: Maybe<Array<Scalars['String']>>;
  newMissedProductsUrl?: Maybe<Scalars['String']>;
  marketplaceResponse?: Maybe<Array<Scalars['String']>>;
  allCount?: Maybe<Scalars['Int']>;
  errorMessage?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTimeTz'];
};

/** A paginated list of Import items. */
export type ImportPaginator = {
  __typename?: 'ImportPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Import items. */
  data: Array<Import>;
};

/** deprecated */
export type ImportProperty = {
  __typename?: 'ImportProperty';
  from: Scalars['String'];
  to?: Maybe<Scalars['String']>;
};

/** deprecated */
export type ImportPropertyConfiguration = {
  __typename?: 'ImportPropertyConfiguration';
  key: Scalars['String'];
  readable: Scalars['String'];
  optional: Scalars['Boolean'];
};

/** deprecated */
export type ImportPropertyInput = {
  from: Scalars['String'];
  to: Scalars['String'];
};

export type ImportResponse = {
  __typename?: 'ImportResponse';
  status: ImportResponseStatus;
  message: Scalars['String'];
};

export enum ImportResponseStatus {
  StartFailed = 'START_FAILED',
  Started = 'STARTED'
}

export enum ImportStatus {
  Created = 'CREATED',
  FileGenerating = 'FILE_GENERATING',
  FileGenerated = 'FILE_GENERATED',
  ImportStarted = 'IMPORT_STARTED',
  Success = 'SUCCESS',
  Error = 'ERROR'
}

export type IncomingInvoice = {
  __typename?: 'IncomingInvoice';
  id: Scalars['ID'];
  invoiceNumber: Scalars['String'];
  supplier: Supplier;
  orderItemGroup?: Maybe<OrderItemGroup>;
  amount: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  status: InvoiceStatus;
  invoiceCreatedAt: Scalars['Date'];
};

export type IncomingInvoiceFilter = {
  supplier?: Maybe<Scalars['ID']>;
  status?: Maybe<InvoiceStatus>;
  searchTerm?: Maybe<Scalars['String']>;
  dateRange?: Maybe<DateRangeFilter>;
};

/** A paginated list of IncomingInvoice items. */
export type IncomingInvoicePaginator = {
  __typename?: 'IncomingInvoicePaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of IncomingInvoice items. */
  data: Array<IncomingInvoice>;
};

export enum InvoiceStatus {
  Paid = 'PAID',
  NotPaid = 'NOT_PAID'
}

export type Like = {
  __typename?: 'Like';
  id: Scalars['ID'];
  user: User;
};

export type LikeInput = {
  likeableId: Scalars['ID'];
  likeableType?: Maybe<Likeable>;
};

export enum Likeable {
  Post = 'POST',
  Comment = 'COMMENT'
}

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LogoutResponse = {
  __typename?: 'LogoutResponse';
  status: LogoutStatus;
  message: Scalars['String'];
};

export enum LogoutStatus {
  /** TOKEN_REVOKED */
  TokenRevoked = 'TOKEN_REVOKED'
}

export enum ManualOrderItemStatus {
  /** Beszerzés előtt */
  PriorToPurchase = 'PRIOR_TO_PURCHASE',
  /** Beszerzés alatt */
  Shipping = 'SHIPPING',
  /** Beszerezve */
  Purchased = 'PURCHASED'
}

export type Marketplace = {
  __typename?: 'Marketplace';
  /** Ez egy azonosító */
  slug?: Maybe<MarketplaceSlug>;
  /** Ezt az elnevezése, ezt írjuk ki */
  name: Scalars['String'];
  currency: Currency;
};

export type MarketplaceProperty = {
  __typename?: 'MarketplaceProperty';
  key: Scalars['String'];
  readable: Scalars['String'];
  optional: Scalars['Boolean'];
};

export enum MarketplaceSlug {
  Unas = 'UNAS',
  Kaufland = 'KAUFLAND'
}


export type Mutation = {
  __typename?: 'Mutation';
  login: AccessToken;
  logout: LogoutResponse;
  updatePassword: UpdatePasswordResponse;
  forgotPassword: ForgotPasswordResponse;
  resetPassword: ResetPasswordResponse;
  createUser: User;
  updateUser?: Maybe<User>;
  deleteUser?: Maybe<User>;
  updateMe?: Maybe<User>;
  updateProduct?: Maybe<Product>;
  updateProducts?: Maybe<Array<Maybe<Product>>>;
  deleteProduct?: Maybe<Product>;
  deleteProducts?: Maybe<Array<Maybe<Product>>>;
  updateOrder?: Maybe<Order>;
  updateOrders?: Maybe<Array<Maybe<Order>>>;
  deleteOrder?: Maybe<Order>;
  updateOrderItem?: Maybe<OrderItem>;
  updateOrderItems?: Maybe<Array<Maybe<OrderItem>>>;
  deleteOrderItem?: Maybe<OrderItem>;
  addOrderItemToStock?: Maybe<OrderItem>;
  addOrderItemsToGroup?: Maybe<Array<OrderItem>>;
  removeOrderItemsFromGroup?: Maybe<Array<OrderItem>>;
  createStocks?: Maybe<Scalars['String']>;
  updateStocks?: Maybe<Scalars['String']>;
  deleteStock?: Maybe<Scalars['String']>;
  deleteStocks?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploadImage?: Maybe<Image>;
  createPost?: Maybe<Post>;
  updatePost?: Maybe<Post>;
  deletePost?: Maybe<Post>;
  createLike?: Maybe<Like>;
  deleteLike?: Maybe<Like>;
  createComment?: Maybe<Comment>;
  refreshStats: RefreshStatsResponse;
  createImport: Import;
  generateFileForImport: ImportResponse;
  startImport: ImportResponse;
  startPlainImport: ImportResponse;
  createEanCode: EanCode;
  updateEanCode: EanCode;
  deleteEanCode: EanCode;
  deleteEanCodes?: Maybe<Array<EanCode>>;
  updateOutgoingInvoice: OutgoingInvoice;
  deleteOutgoingInvoice: OutgoingInvoice;
  deleteOutgoingInvoices?: Maybe<Array<OutgoingInvoice>>;
  createIncomingInvoice: IncomingInvoice;
  updateIncomingInvoice: IncomingInvoice;
  deleteIncomingInvoice: IncomingInvoice;
  deleteIncomingInvoices?: Maybe<Array<IncomingInvoice>>;
  createSupplier: Supplier;
  updateSupplier: Supplier;
  updateSupplierConfiguration: Supplier;
  deleteSupplier: Supplier;
  createEvent: Event;
  updateEvent: Event;
  deleteEvent: Event;
  createOrderItemGroup: OrderItemGroup;
  updateOrderItemGroup: OrderItemGroup;
  deleteOrderItemGroup: OrderItemGroup;
  deleteOrderItemGroups: Array<OrderItemGroup>;
};


export type MutationLoginArgs = {
  input?: Maybe<LoginInput>;
};


export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput;
};


export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
  id: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateMeArgs = {
  input: UpdateMeInput;
};


export type MutationUpdateProductArgs = {
  input: ProductInput;
  id: Scalars['ID'];
};


export type MutationUpdateProductsArgs = {
  input: BulkUpdateProductInput;
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationDeleteProductArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProductsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateOrderArgs = {
  input: OrderInput;
  id: Scalars['ID'];
};


export type MutationUpdateOrdersArgs = {
  input: BulkUpdateOrderInput;
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationDeleteOrderArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateOrderItemArgs = {
  input: OrderItemInput;
  id: Scalars['ID'];
};


export type MutationUpdateOrderItemsArgs = {
  input: BulkUpdateOrderItemInput;
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationDeleteOrderItemArgs = {
  id: Scalars['ID'];
};


export type MutationAddOrderItemToStockArgs = {
  input: OrderItemToStockInput;
  id: Scalars['ID'];
};


export type MutationAddOrderItemsToGroupArgs = {
  input: OrderItemToGroupInput;
};


export type MutationRemoveOrderItemsFromGroupArgs = {
  input: OrderItemToGroupInput;
};


export type MutationCreateStocksArgs = {
  input: CreateStockInput;
};


export type MutationUpdateStocksArgs = {
  input: UpdateStockInput;
  ean: Scalars['String'];
};


export type MutationDeleteStockArgs = {
  ean: Scalars['String'];
};


export type MutationDeleteStocksArgs = {
  eans: Array<Scalars['String']>;
};


export type MutationUploadImageArgs = {
  file: Scalars['Upload'];
};


export type MutationCreatePostArgs = {
  input: PostInput;
};


export type MutationUpdatePostArgs = {
  input: PostInput;
  id: Scalars['ID'];
};


export type MutationDeletePostArgs = {
  id: Scalars['ID'];
};


export type MutationCreateLikeArgs = {
  input: LikeInput;
};


export type MutationDeleteLikeArgs = {
  input: LikeInput;
};


export type MutationCreateCommentArgs = {
  input: CommentInput;
};


export type MutationCreateImportArgs = {
  input: CreateImportInput;
};


export type MutationGenerateFileForImportArgs = {
  input: GenerateFileForImportInput;
  id: Scalars['ID'];
};


export type MutationStartImportArgs = {
  input: StartImportInput;
  id: Scalars['ID'];
};


export type MutationStartPlainImportArgs = {
  input: StartPlainImportInput;
};


export type MutationCreateEanCodeArgs = {
  input: EanCodeInput;
};


export type MutationUpdateEanCodeArgs = {
  input: EanCodeInput;
  id: Scalars['ID'];
};


export type MutationDeleteEanCodeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteEanCodesArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateOutgoingInvoiceArgs = {
  input: OutgoingInvoiceInput;
  id: Scalars['ID'];
};


export type MutationDeleteOutgoingInvoiceArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOutgoingInvoicesArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationCreateIncomingInvoiceArgs = {
  input?: Maybe<CreateIncomingInvoiceInput>;
};


export type MutationUpdateIncomingInvoiceArgs = {
  input: UpdateIncomingInvoiceInput;
  id: Scalars['ID'];
};


export type MutationDeleteIncomingInvoiceArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteIncomingInvoicesArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationCreateSupplierArgs = {
  input?: Maybe<CreateSupplierInput>;
};


export type MutationUpdateSupplierArgs = {
  input: UpdateSupplierInput;
  id: Scalars['ID'];
};


export type MutationUpdateSupplierConfigurationArgs = {
  input: SupplierConfigurationInput;
  supplierId: Scalars['ID'];
  marketplace: MarketplaceSlug;
};


export type MutationDeleteSupplierArgs = {
  id: Scalars['ID'];
};


export type MutationCreateEventArgs = {
  input: CreateEventInput;
};


export type MutationUpdateEventArgs = {
  input: UpdateEventInput;
  id: Scalars['ID'];
};


export type MutationDeleteEventArgs = {
  id: Scalars['ID'];
};


export type MutationCreateOrderItemGroupArgs = {
  input: CreateOrderItemGroupInput;
};


export type MutationUpdateOrderItemGroupArgs = {
  input: UpdateOrderItemGroupInput;
  id: Scalars['ID'];
};


export type MutationDeleteOrderItemGroupArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOrderItemGroupsArgs = {
  ids: Array<Scalars['ID']>;
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  externalId: Scalars['String'];
  marketplace: Marketplace;
  orderCreatedAt?: Maybe<Scalars['DateTimeTz']>;
  status: OrderStatus;
  isPaid: Scalars['Boolean'];
  note?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Address>;
  shippingAddress?: Maybe<Address>;
  paymentType?: Maybe<Scalars['String']>;
  shippingProvider?: Maybe<Scalars['String']>;
  shippingNumber?: Maybe<Scalars['String']>;
  customer?: Maybe<Customer>;
  items?: Maybe<Array<OrderItem>>;
  itemsTotal: Scalars['Float'];
  vatTotal: Scalars['Float'];
  orderTotal: Scalars['Float'];
  shippingFeeNet: Scalars['Float'];
  shippingFeeGross: Scalars['Float'];
  isInvoiced: Scalars['Boolean'];
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export type OrderFilter = {
  marketplace?: Maybe<MarketplaceSlug>;
  status?: Maybe<OrderStatus>;
  searchTerm?: Maybe<Scalars['String']>;
};

export type OrderInput = {
  orderCreatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<OrderStatus>;
  shippingProvider?: Maybe<Scalars['String']>;
  shippingNumber?: Maybe<Scalars['String']>;
  isPaid?: Maybe<Scalars['Boolean']>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  id: Scalars['ID'];
  order: Order;
  /** A product ean kódja a rendeléskor */
  ean: Scalars['String'];
  /** A product neve a rendeléskor */
  name: Scalars['String'];
  quantity?: Maybe<Scalars['Int']>;
  status: OrderItemStatus;
  supplier?: Maybe<Supplier>;
  priceNet: Scalars['Float'];
  priceGross: Scalars['Float'];
  /** Ez a product aktuális értékeit tartalmazza, ha törlik a productot ez null */
  product?: Maybe<Product>;
  inStock: Scalars['Int'];
  canAddToStock: Scalars['Boolean'];
  group?: Maybe<OrderItemGroup>;
};

export type OrderItemFilter = {
  supplier?: Maybe<Scalars['ID']>;
  orderCreatedAt?: Maybe<Scalars['Date']>;
  searchTerm?: Maybe<Scalars['String']>;
  productCategory?: Maybe<Scalars['String']>;
};

export type OrderItemGroup = {
  __typename?: 'OrderItemGroup';
  id: Scalars['ID'];
  date: Scalars['Date'];
  supplier: Supplier;
  items: Array<OrderItem>;
};

export type OrderItemGroupAllFilter = {
  date: Scalars['Date'];
};

export type OrderItemGroupFilter = {
  searchTerm?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
};

/** A paginated list of OrderItemGroup items. */
export type OrderItemGroupPaginator = {
  __typename?: 'OrderItemGroupPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of OrderItemGroup items. */
  data: Array<OrderItemGroup>;
};

export type OrderItemInput = {
  supplierId?: Maybe<Scalars['ID']>;
  status?: Maybe<ManualOrderItemStatus>;
};

/** A paginated list of OrderItem items. */
export type OrderItemPaginator = {
  __typename?: 'OrderItemPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of OrderItem items. */
  data: Array<OrderItem>;
};

export enum OrderItemStatus {
  /** Beszerzés előtt */
  PriorToPurchase = 'PRIOR_TO_PURCHASE',
  /** Beszerzés alatt */
  Shipping = 'SHIPPING',
  /** Beszerezve */
  Purchased = 'PURCHASED',
  /** Raktárról */
  FromStock = 'FROM_STOCK'
}

export type OrderItemToGroupInput = {
  orderItemIds: Array<Scalars['ID']>;
  groupId: Scalars['ID'];
};

export type OrderItemToStockInput = {
  quantity: Scalars['Int'];
};

/** A paginated list of Order items. */
export type OrderPaginator = {
  __typename?: 'OrderPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Order items. */
  data: Array<Order>;
};

export type OrderSort = {
  by: OrderSortValue;
  direction: SortDirection;
};

export enum OrderSortValue {
  Price = 'PRICE',
  OrderCreatedAt = 'ORDER_CREATED_AT'
}

export enum OrderStatus {
  /** Feldolgozás alatt */
  InProgress = 'IN_PROGRESS',
  /** Feldolgozva */
  Processed = 'PROCESSED',
  /** Teljesítve */
  Completed = 'COMPLETED',
  /** Visszamondva */
  Cancelled = 'CANCELLED'
}

export type OutgoingInvoice = {
  __typename?: 'OutgoingInvoice';
  id: Scalars['ID'];
  status: InvoiceStatus;
  order: Order;
  provider: Scalars['String'];
  invoiceCreatedAt: Scalars['Date'];
  url: Scalars['String'];
};

export type OutgoingInvoiceFilter = {
  provider?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceStatus>;
  searchTerm?: Maybe<Scalars['String']>;
  dateRange?: Maybe<DateRangeFilter>;
};

export type OutgoingInvoiceInput = {
  status: InvoiceStatus;
};

/** A paginated list of OutgoingInvoice items. */
export type OutgoingInvoicePaginator = {
  __typename?: 'OutgoingInvoicePaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of OutgoingInvoice items. */
  data: Array<OutgoingInvoice>;
};

/** Information about pagination using a Relay style cursor connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** The cursor to continue paginating backwards. */
  startCursor?: Maybe<Scalars['String']>;
  /** The cursor to continue paginating forwards. */
  endCursor?: Maybe<Scalars['String']>;
  /** Total number of nodes in the paginated connection. */
  total: Scalars['Int'];
  /** Number of nodes in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
};

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

export type PeriodChart = {
  __typename?: 'PeriodChart';
  prev: Chart;
  current: Chart;
};

export type Post = {
  __typename?: 'Post';
  id: Scalars['ID'];
  user: User;
  isLiked: Scalars['Boolean'];
  likes?: Maybe<Array<Like>>;
  body?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
  comments?: Maybe<Array<Comment>>;
  createdAt: Scalars['DateTimeTz'];
};

export type PostInput = {
  body?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['ID']>;
};

/** A paginated list of Post items. */
export type PostPaginator = {
  __typename?: 'PostPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Post items. */
  data: Array<Post>;
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  ean: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  shortDescription?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  listPrice?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  minPrice?: Maybe<Scalars['Float']>;
  maxPrice?: Maybe<Scalars['Float']>;
  minDeliveryTime?: Maybe<Scalars['Int']>;
  maxDeliveryTime?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  deliveryCost?: Maybe<Scalars['String']>;
  deliveryTime?: Maybe<Scalars['String']>;
  marketplace: Marketplace;
  createdAt?: Maybe<Scalars['Date']>;
};

export type ProductFilter = {
  category?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  searchTerm?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['ID']>;
};

export type ProductInput = {
  name?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  shortDescription?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  listPrice?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  minPrice?: Maybe<Scalars['Float']>;
  maxPrice?: Maybe<Scalars['Float']>;
  minDeliveryTime?: Maybe<Scalars['Int']>;
  maxDeliveryTime?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
};

/** A paginated list of Product items. */
export type ProductPaginator = {
  __typename?: 'ProductPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Product items. */
  data: Array<Product>;
};

export type Query = {
  __typename?: 'Query';
  hello: Scalars['String'];
  greet: Scalars['String'];
  me?: Maybe<User>;
  user?: Maybe<User>;
  product?: Maybe<Product>;
  productCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  marketplaces: Array<Marketplace>;
  order?: Maybe<Order>;
  orderByShippingNumber?: Maybe<Array<Order>>;
  orderItemSuppliers?: Maybe<Array<Supplier>>;
  image?: Maybe<Image>;
  post?: Maybe<Post>;
  ordersCountCurrentStat: Chart;
  revenueCurrentStat: Chart;
  revenueWeeklyStat: PeriodChart;
  revenueMonthlyStat: PeriodChart;
  revenueYearlyStat: PeriodChart;
  showImport?: Maybe<Import>;
  showImportProperties: Array<ImportPropertyConfiguration>;
  incomingInvoice?: Maybe<IncomingInvoice>;
  suppliers?: Maybe<Array<Supplier>>;
  supplier?: Maybe<Supplier>;
  showMarketplaceProperties: Array<MarketplaceProperty>;
  events?: Maybe<Array<Event>>;
  eventsToday?: Maybe<Array<Event>>;
  event?: Maybe<Event>;
  orderItemGroupsAll?: Maybe<Array<OrderItemGroup>>;
  users?: Maybe<UserPaginator>;
  products?: Maybe<ProductPaginator>;
  orders?: Maybe<OrderPaginator>;
  orderItems?: Maybe<OrderItemPaginator>;
  stocks?: Maybe<StockPaginator>;
  posts?: Maybe<PostPaginator>;
  imports?: Maybe<ImportPaginator>;
  eanCodes?: Maybe<EanCodePaginator>;
  outgoingInvoices?: Maybe<OutgoingInvoicePaginator>;
  incomingInvoices?: Maybe<IncomingInvoicePaginator>;
  orderItemGroups?: Maybe<OrderItemGroupPaginator>;
};


export type QueryGreetArgs = {
  name?: Maybe<Scalars['String']>;
};


export type QueryUserArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryProductArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryProductCategoriesArgs = {
  name: Scalars['String'];
};


export type QueryOrderArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryOrderByShippingNumberArgs = {
  shippingNumber: Scalars['String'];
};


export type QueryOrderItemSuppliersArgs = {
  orderItemId: Scalars['ID'];
};


export type QueryImageArgs = {
  id: Scalars['ID'];
};


export type QueryPostArgs = {
  id: Scalars['ID'];
};


export type QueryRevenueWeeklyStatArgs = {
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
};


export type QueryRevenueMonthlyStatArgs = {
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
};


export type QueryRevenueYearlyStatArgs = {
  year?: Maybe<Scalars['Int']>;
};


export type QueryShowImportArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryShowImportPropertiesArgs = {
  id: Scalars['ID'];
};


export type QueryIncomingInvoiceArgs = {
  id: Scalars['ID'];
};


export type QuerySupplierArgs = {
  id: Scalars['ID'];
};


export type QueryShowMarketplacePropertiesArgs = {
  marketplace: MarketplaceSlug;
};


export type QueryEventsArgs = {
  filter: EventFilter;
};


export type QueryEventArgs = {
  id: Scalars['ID'];
};


export type QueryOrderItemGroupsAllArgs = {
  filter: OrderItemGroupAllFilter;
};


export type QueryUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryProductsArgs = {
  marketplace: MarketplaceSlug;
  filter?: Maybe<ProductFilter>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryOrdersArgs = {
  filter?: Maybe<OrderFilter>;
  sort?: Maybe<OrderSort>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryOrderItemsArgs = {
  filter?: Maybe<OrderItemFilter>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryStocksArgs = {
  filter?: Maybe<StockFilter>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryPostsArgs = {
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryImportsArgs = {
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryEanCodesArgs = {
  filter?: Maybe<EanCodeFilter>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryOutgoingInvoicesArgs = {
  filter?: Maybe<OutgoingInvoiceFilter>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryIncomingInvoicesArgs = {
  filter?: Maybe<IncomingInvoiceFilter>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryOrderItemGroupsArgs = {
  filter?: Maybe<OrderItemGroupFilter>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type RefreshStatsResponse = {
  __typename?: 'RefreshStatsResponse';
  status: RefreshStatsStatus;
};

export enum RefreshStatsStatus {
  StatsRefreshed = 'STATS_REFRESHED'
}

export type ResetPasswordInput = {
  email: Scalars['String'];
  token: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};

export type ResetPasswordResponse = {
  __typename?: 'ResetPasswordResponse';
  status: ResetPasswordStatus;
  message?: Maybe<Scalars['String']>;
};

export enum ResetPasswordStatus {
  /** PASSWORD_RESET */
  PasswordReset = 'PASSWORD_RESET'
}

/** The available SQL operators that are used to filter query results. */
export enum SqlOperator {
  /** Equal operator (`=`) */
  Eq = 'EQ',
  /** Not equal operator (`!=`) */
  Neq = 'NEQ',
  /** Greater than operator (`>`) */
  Gt = 'GT',
  /** Greater than or equal operator (`>=`) */
  Gte = 'GTE',
  /** Less than operator (`<`) */
  Lt = 'LT',
  /** Less than or equal operator (`<=`) */
  Lte = 'LTE',
  /** Simple pattern matching (`LIKE`) */
  Like = 'LIKE',
  /** Negation of simple pattern matching (`NOT LIKE`) */
  NotLike = 'NOT_LIKE',
  /** Whether a value is within a set of values (`IN`) */
  In = 'IN',
  /** Whether a value is not within a set of values (`NOT IN`) */
  NotIn = 'NOT_IN',
  /** Whether a value is within a range of values (`BETWEEN`) */
  Between = 'BETWEEN',
  /** Whether a value is not within a range of values (`NOT BETWEEN`) */
  NotBetween = 'NOT_BETWEEN',
  /** Whether a value is null (`IS NULL`) */
  IsNull = 'IS_NULL',
  /** Whether a value is not null (`IS NOT NULL`) */
  IsNotNull = 'IS_NOT_NULL'
}

/** Information about pagination using a simple paginator. */
export type SimplePaginatorInfo = {
  __typename?: 'SimplePaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Number of items per page. */
  perPage: Scalars['Int'];
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** The available directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export type StartImportInput = {
  file?: Maybe<Scalars['Upload']>;
};

export type StartPlainImportInput = {
  file: Scalars['Upload'];
  lang: Scalars['String'];
  marketplace: MarketplaceSlug;
};

export type Stat = {
  __typename?: 'Stat';
  value: Scalars['ID'];
  unit?: Maybe<Scalars['String']>;
};

export type Stock = {
  __typename?: 'Stock';
  ean: Scalars['String'];
  names?: Maybe<Array<Scalars['String']>>;
  quantity?: Maybe<Scalars['Int']>;
  categories?: Maybe<Array<Scalars['String']>>;
  lastUpdatedAt?: Maybe<Scalars['DateTime']>;
};

export type StockFilter = {
  category?: Maybe<Scalars['String']>;
  searchTerm?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
};

/** A paginated list of Stock items. */
export type StockPaginator = {
  __typename?: 'StockPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Stock items. */
  data: Array<Stock>;
};

export type Supplier = {
  __typename?: 'Supplier';
  id: Scalars['ID'];
  name: Scalars['String'];
  url: Scalars['String'];
  configurations: Array<SupplierConfiguration>;
};

export type SupplierConfiguration = {
  __typename?: 'SupplierConfiguration';
  marketplace: Marketplace;
  properties: Array<ImportProperty>;
};

export type SupplierConfigurationInput = {
  properties: Array<SupplierConfigurationPropertyInput>;
};

export type SupplierConfigurationProperty = {
  __typename?: 'SupplierConfigurationProperty';
  from: Scalars['String'];
  to?: Maybe<Scalars['String']>;
};

export type SupplierConfigurationPropertyInput = {
  from: Scalars['String'];
  to: Scalars['String'];
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type UpdateEventInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['DateTimeTz']>;
  to?: Maybe<Scalars['DateTimeTz']>;
  userClass?: Maybe<UserClass>;
};

export type UpdateIncomingInvoiceInput = {
  invoiceNumber?: Maybe<Scalars['String']>;
  supplierId?: Maybe<Scalars['ID']>;
  orderItemGroupId?: Maybe<Scalars['ID']>;
  amount?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceStatus>;
  invoiceCreatedAt?: Maybe<Scalars['Date']>;
};

export type UpdateMeInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['ID']>;
};

export type UpdateOrderItemGroupInput = {
  date?: Maybe<Scalars['Date']>;
  orderItemIds: Array<Scalars['ID']>;
  supplierId?: Maybe<Scalars['ID']>;
};

export type UpdatePasswordInput = {
  currentPassword: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};

export type UpdatePasswordResponse = {
  __typename?: 'UpdatePasswordResponse';
  status: UpdatePasswordStatus;
};

export enum UpdatePasswordStatus {
  /** PASSWORD_UPDATED */
  PasswordUpdated = 'PASSWORD_UPDATED'
}

export type UpdateStockInput = {
  quantity: Scalars['Int'];
};

export type UpdateSupplierInput = {
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type UpdateUserInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['ID']>;
  roles?: Maybe<Array<UserRole>>;
  classes?: Maybe<Array<UserClass>>;
};


export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  image?: Maybe<Image>;
  roles?: Maybe<Array<UserRole>>;
  classes?: Maybe<Array<UserClass>>;
};

export enum UserClass {
  /** Ezek még változhatnak csak párat beírtam példának */
  Products = 'PRODUCTS',
  Invoices = 'INVOICES',
  Customers = 'CUSTOMERS'
}

/** A paginated list of User items. */
export type UserPaginator = {
  __typename?: 'UserPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of User items. */
  data: Array<User>;
};

export enum UserRole {
  Admin = 'ADMIN',
  Editor = 'EDITOR',
  Reader = 'READER'
}

/** Dynamic WHERE conditions for queries. */
export type WhereConditions = {
  /** The column that is used for the condition. */
  column?: Maybe<Scalars['String']>;
  /** The operator that is used for the condition. */
  operator?: Maybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: Maybe<Scalars['Mixed']>;
  /** A set of conditions that requires all conditions to match. */
  AND?: Maybe<Array<WhereConditions>>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: Maybe<Array<WhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: Maybe<WhereConditionsRelation>;
};

/** Dynamic HAS conditions for WHERE condition queries. */
export type WhereConditionsRelation = {
  /** The relation that is checked. */
  relation: Scalars['String'];
  /** The comparison operator to test against the amount. */
  operator?: Maybe<SqlOperator>;
  /** The amount to test. */
  amount?: Maybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: Maybe<WhereConditions>;
};

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name'>
  ) }
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name'>
  )> }
);

export type ForgotPasswordMutationVariables = Exact<{
  input: ForgotPasswordInput;
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & { forgotPassword: (
    { __typename?: 'ForgotPasswordResponse' }
    & Pick<ForgotPasswordResponse, 'status' | 'message'>
  ) }
);

export type LoginMutationVariables = Exact<{
  input?: Maybe<LoginInput>;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'AccessToken' }
    & Pick<AccessToken, 'token'>
  ) }
);

export type LogOutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogOutMutation = (
  { __typename?: 'Mutation' }
  & { logout: (
    { __typename?: 'LogoutResponse' }
    & Pick<LogoutResponse, 'message' | 'status'>
  ) }
);

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword: (
    { __typename?: 'ResetPasswordResponse' }
    & Pick<ResetPasswordResponse, 'message' | 'status'>
  ) }
);

export type UpdateMeMutationVariables = Exact<{
  input: UpdateMeInput;
}>;


export type UpdateMeMutation = (
  { __typename?: 'Mutation' }
  & { updateMe?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type UpdatePasswordMutationVariables = Exact<{
  input: UpdatePasswordInput;
}>;


export type UpdatePasswordMutation = (
  { __typename?: 'Mutation' }
  & { updatePassword: (
    { __typename?: 'UpdatePasswordResponse' }
    & Pick<UpdatePasswordResponse, 'status'>
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
  id: Scalars['ID'];
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'email' | 'roles' | 'classes'>
    & { image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'url'>
    )> }
  )> }
);

export type UsersQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<(
    { __typename?: 'UserPaginator' }
    & { paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & PaginationFragment
    ), data: Array<(
      { __typename?: 'User' }
      & UserPropsFragment
    )> }
  )> }
);

export type UserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserPropsFragment
  )> }
);

export type CreateEventMutationVariables = Exact<{
  input: CreateEventInput;
}>;


export type CreateEventMutation = (
  { __typename?: 'Mutation' }
  & { createEvent: (
    { __typename?: 'Event' }
    & Pick<Event, 'title'>
  ) }
);

export type DeleteEventMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteEventMutation = (
  { __typename?: 'Mutation' }
  & { deleteEvent: (
    { __typename?: 'Event' }
    & Pick<Event, 'title'>
  ) }
);

export type UpdateEventMutationVariables = Exact<{
  input: UpdateEventInput;
  id: Scalars['ID'];
}>;


export type UpdateEventMutation = (
  { __typename?: 'Mutation' }
  & { updateEvent: (
    { __typename?: 'Event' }
    & EventFragment
  ) }
);

export type EventFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'title' | 'description' | 'from' | 'to' | 'userClass'>
);

export type EventQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type EventQuery = (
  { __typename?: 'Query' }
  & { event?: Maybe<(
    { __typename?: 'Event' }
    & EventFragment
  )> }
);

export type EventsQueryVariables = Exact<{
  filter: EventFilter;
}>;


export type EventsQuery = (
  { __typename?: 'Query' }
  & { events?: Maybe<Array<(
    { __typename?: 'Event' }
    & EventFragment
  )>> }
);

export type EventsTodayQueryVariables = Exact<{ [key: string]: never; }>;


export type EventsTodayQuery = (
  { __typename?: 'Query' }
  & { eventsToday?: Maybe<Array<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'title' | 'from' | 'to'>
  )>> }
);

export type CreateEanMutationVariables = Exact<{
  input: EanCodeInput;
}>;


export type CreateEanMutation = (
  { __typename?: 'Mutation' }
  & { createEanCode: (
    { __typename?: 'EanCode' }
    & Pick<EanCode, 'id' | 'ean' | 'type'>
  ) }
);

export type DeleteEanMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteEanMutation = (
  { __typename?: 'Mutation' }
  & { deleteEanCode: (
    { __typename?: 'EanCode' }
    & Pick<EanCode, 'ean'>
  ) }
);

export type DeleteEansMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DeleteEansMutation = (
  { __typename?: 'Mutation' }
  & { deleteEanCodes?: Maybe<Array<(
    { __typename?: 'EanCode' }
    & Pick<EanCode, 'ean'>
  )>> }
);

export type UpdateEanMutationVariables = Exact<{
  input: EanCodeInput;
  id: Scalars['ID'];
}>;


export type UpdateEanMutation = (
  { __typename?: 'Mutation' }
  & { updateEanCode: (
    { __typename?: 'EanCode' }
    & Pick<EanCode, 'id' | 'ean' | 'type'>
  ) }
);

export type EanCodesQueryVariables = Exact<{
  filter?: Maybe<EanCodeFilter>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type EanCodesQuery = (
  { __typename?: 'Query' }
  & { eanCodes?: Maybe<(
    { __typename?: 'EanCodePaginator' }
    & { paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & PaginationFragment
    ), data: Array<(
      { __typename?: 'EanCode' }
      & Pick<EanCode, 'id' | 'ean' | 'type'>
    )> }
  )> }
);

export type OrderPropsFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'externalId' | 'isPaid' | 'isInvoiced' | 'orderCreatedAt' | 'note' | 'itemsTotal' | 'vatTotal' | 'orderTotal' | 'status' | 'paymentType' | 'shippingFeeNet' | 'shippingNumber' | 'shippingProvider'>
  & { items?: Maybe<Array<(
    { __typename?: 'OrderItem' }
    & Pick<OrderItem, 'id' | 'ean' | 'name' | 'status' | 'quantity' | 'priceGross'>
    & { product?: Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name'>
    )>, supplier?: Maybe<(
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'id' | 'name'>
    )>, order: (
      { __typename?: 'Order' }
      & { marketplace: (
        { __typename?: 'Marketplace' }
        & Pick<Marketplace, 'slug' | 'name'>
        & { currency: (
          { __typename?: 'Currency' }
          & Pick<Currency, 'prefix' | 'suffix'>
        ) }
      ) }
    ) }
  )>>, marketplace: (
    { __typename?: 'Marketplace' }
    & Pick<Marketplace, 'slug' | 'name'>
    & { currency: (
      { __typename?: 'Currency' }
      & Pick<Currency, 'slug' | 'prefix' | 'suffix'>
    ) }
  ), billingAddress?: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'name' | 'street' | 'zip' | 'city' | 'country'>
  )>, customer?: Maybe<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'email' | 'name' | 'phone'>
  )>, shippingAddress?: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'name' | 'city' | 'zip' | 'country' | 'street'>
  )> }
);

export type PaginationFragment = (
  { __typename?: 'PaginatorInfo' }
  & Pick<PaginatorInfo, 'count' | 'currentPage' | 'firstItem' | 'hasMorePages' | 'lastItem' | 'lastPage' | 'perPage' | 'total'>
);

export type SupplierPropsFragment = (
  { __typename?: 'Supplier' }
  & Pick<Supplier, 'id' | 'name' | 'url'>
  & { configurations: Array<(
    { __typename?: 'SupplierConfiguration' }
    & { marketplace: (
      { __typename?: 'Marketplace' }
      & Pick<Marketplace, 'slug'>
    ), properties: Array<(
      { __typename?: 'ImportProperty' }
      & Pick<ImportProperty, 'from' | 'to'>
    )> }
  )> }
);

export type UserPropsFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'email' | 'roles' | 'classes'>
  & { image?: Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'url'>
  )> }
);

export type UploadImageMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadImageMutation = (
  { __typename?: 'Mutation' }
  & { uploadImage?: Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'id'>
  )> }
);

export type CreateIncomingInvoiceMutationVariables = Exact<{
  input?: Maybe<CreateIncomingInvoiceInput>;
}>;


export type CreateIncomingInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { createIncomingInvoice: (
    { __typename?: 'IncomingInvoice' }
    & Pick<IncomingInvoice, 'invoiceNumber'>
  ) }
);

export type DeleteIncomingInvoiceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteIncomingInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { deleteIncomingInvoice: (
    { __typename?: 'IncomingInvoice' }
    & Pick<IncomingInvoice, 'invoiceNumber'>
  ) }
);

export type DeleteIncomingInvoicesMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DeleteIncomingInvoicesMutation = (
  { __typename?: 'Mutation' }
  & { deleteIncomingInvoices?: Maybe<Array<(
    { __typename?: 'IncomingInvoice' }
    & Pick<IncomingInvoice, 'invoiceNumber'>
  )>> }
);

export type DeleteOutgoingInvoiceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteOutgoingInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { deleteOutgoingInvoice: (
    { __typename?: 'OutgoingInvoice' }
    & Pick<OutgoingInvoice, 'id'>
  ) }
);

export type DeleteOutgoingInvoicesMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DeleteOutgoingInvoicesMutation = (
  { __typename?: 'Mutation' }
  & { deleteOutgoingInvoices?: Maybe<Array<(
    { __typename?: 'OutgoingInvoice' }
    & Pick<OutgoingInvoice, 'id'>
  )>> }
);

export type UpdateIncomingInvoiceMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateIncomingInvoiceInput;
}>;


export type UpdateIncomingInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { updateIncomingInvoice: (
    { __typename?: 'IncomingInvoice' }
    & Pick<IncomingInvoice, 'invoiceNumber' | 'status'>
  ) }
);

export type UpdateOutgoingInvoiceMutationVariables = Exact<{
  id: Scalars['ID'];
  input: OutgoingInvoiceInput;
}>;


export type UpdateOutgoingInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { updateOutgoingInvoice: (
    { __typename?: 'OutgoingInvoice' }
    & Pick<OutgoingInvoice, 'status'>
  ) }
);

export type IncomingInvoicesQueryVariables = Exact<{
  filter?: Maybe<IncomingInvoiceFilter>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type IncomingInvoicesQuery = (
  { __typename?: 'Query' }
  & { incomingInvoices?: Maybe<(
    { __typename?: 'IncomingInvoicePaginator' }
    & { paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & PaginationFragment
    ), data: Array<(
      { __typename?: 'IncomingInvoice' }
      & Pick<IncomingInvoice, 'id' | 'invoiceNumber' | 'amount' | 'note' | 'status' | 'invoiceCreatedAt'>
      & { supplier: (
        { __typename?: 'Supplier' }
        & Pick<Supplier, 'id' | 'name' | 'url'>
      ), orderItemGroup?: Maybe<(
        { __typename?: 'OrderItemGroup' }
        & Pick<OrderItemGroup, 'id'>
      )> }
    )> }
  )> }
);

export type OutgoingInvoicesQueryVariables = Exact<{
  filter?: Maybe<OutgoingInvoiceFilter>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type OutgoingInvoicesQuery = (
  { __typename?: 'Query' }
  & { outgoingInvoices?: Maybe<(
    { __typename?: 'OutgoingInvoicePaginator' }
    & { paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & PaginationFragment
    ), data: Array<(
      { __typename?: 'OutgoingInvoice' }
      & Pick<OutgoingInvoice, 'id' | 'status' | 'provider' | 'invoiceCreatedAt' | 'url'>
      & { order: (
        { __typename?: 'Order' }
        & Pick<Order, 'id' | 'orderTotal'>
        & { marketplace: (
          { __typename?: 'Marketplace' }
          & { currency: (
            { __typename?: 'Currency' }
            & Pick<Currency, 'slug' | 'prefix' | 'suffix'>
          ) }
        ), customer?: Maybe<(
          { __typename?: 'Customer' }
          & Pick<Customer, 'name'>
        )> }
      ) }
    )> }
  )> }
);

export type AddOrderItemsToGroupMutationVariables = Exact<{
  input: OrderItemToGroupInput;
}>;


export type AddOrderItemsToGroupMutation = (
  { __typename?: 'Mutation' }
  & { addOrderItemsToGroup?: Maybe<Array<(
    { __typename?: 'OrderItem' }
    & Pick<OrderItem, 'name'>
  )>> }
);

export type CreateOrderItemGroupMutationVariables = Exact<{
  input: CreateOrderItemGroupInput;
}>;


export type CreateOrderItemGroupMutation = (
  { __typename?: 'Mutation' }
  & { createOrderItemGroup: (
    { __typename?: 'OrderItemGroup' }
    & Pick<OrderItemGroup, 'date'>
    & { supplier: (
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'name'>
    ) }
  ) }
);

export type DeleteOrderItemGroupMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteOrderItemGroupMutation = (
  { __typename?: 'Mutation' }
  & { deleteOrderItemGroup: (
    { __typename?: 'OrderItemGroup' }
    & Pick<OrderItemGroup, 'id'>
  ) }
);

export type RemoveOrderItemsFromGroupMutationVariables = Exact<{
  input: OrderItemToGroupInput;
}>;


export type RemoveOrderItemsFromGroupMutation = (
  { __typename?: 'Mutation' }
  & { removeOrderItemsFromGroup?: Maybe<Array<(
    { __typename?: 'OrderItem' }
    & Pick<OrderItem, 'id'>
  )>> }
);

export type UpdateOrderItemGroupMutationVariables = Exact<{
  input: UpdateOrderItemGroupInput;
  id: Scalars['ID'];
}>;


export type UpdateOrderItemGroupMutation = (
  { __typename?: 'Mutation' }
  & { updateOrderItemGroup: (
    { __typename?: 'OrderItemGroup' }
    & Pick<OrderItemGroup, 'date'>
    & { supplier: (
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'name'>
    ) }
  ) }
);

export type OrderItemGroupsQueryVariables = Exact<{
  filter?: Maybe<OrderItemGroupFilter>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type OrderItemGroupsQuery = (
  { __typename?: 'Query' }
  & { orderItemGroups?: Maybe<(
    { __typename?: 'OrderItemGroupPaginator' }
    & { paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & PaginationFragment
    ), data: Array<(
      { __typename?: 'OrderItemGroup' }
      & Pick<OrderItemGroup, 'id' | 'date'>
      & { supplier: (
        { __typename?: 'Supplier' }
        & Pick<Supplier, 'id' | 'name' | 'url'>
      ), items: Array<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'ean' | 'name' | 'quantity'>
      )> }
    )> }
  )> }
);

export type OrderItemGroupsAllQueryVariables = Exact<{
  filter: OrderItemGroupAllFilter;
}>;


export type OrderItemGroupsAllQuery = (
  { __typename?: 'Query' }
  & { orderItemGroupsAll?: Maybe<Array<(
    { __typename?: 'OrderItemGroup' }
    & Pick<OrderItemGroup, 'id' | 'date'>
    & { supplier: (
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'name'>
    ), items: Array<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'id'>
    )> }
  )>> }
);

export type DeleteOrderItemsMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteOrderItemsMutation = (
  { __typename?: 'Mutation' }
  & { deleteOrderItem?: Maybe<(
    { __typename?: 'OrderItem' }
    & Pick<OrderItem, 'name'>
  )> }
);

export type UpdateOrderItemMutationVariables = Exact<{
  input: OrderItemInput;
  id: Scalars['ID'];
}>;


export type UpdateOrderItemMutation = (
  { __typename?: 'Mutation' }
  & { updateOrderItem?: Maybe<(
    { __typename?: 'OrderItem' }
    & Pick<OrderItem, 'name' | 'status'>
    & { supplier?: Maybe<(
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'name'>
    )> }
  )> }
);

export type OrderItemsQueryVariables = Exact<{
  filter?: Maybe<OrderItemFilter>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type OrderItemsQuery = (
  { __typename?: 'Query' }
  & { orderItems?: Maybe<(
    { __typename?: 'OrderItemPaginator' }
    & { paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & PaginationFragment
    ), data: Array<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'id' | 'name' | 'priceNet' | 'priceGross' | 'quantity' | 'ean' | 'status' | 'inStock' | 'canAddToStock'>
      & { order: (
        { __typename?: 'Order' }
        & Pick<Order, 'id' | 'externalId' | 'orderCreatedAt'>
        & { marketplace: (
          { __typename?: 'Marketplace' }
          & Pick<Marketplace, 'slug' | 'name'>
          & { currency: (
            { __typename?: 'Currency' }
            & Pick<Currency, 'prefix' | 'suffix'>
          ) }
        ) }
      ), product?: Maybe<(
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name'>
      )>, supplier?: Maybe<(
        { __typename?: 'Supplier' }
        & Pick<Supplier, 'id' | 'url' | 'name'>
      )> }
    )> }
  )> }
);

export type OrderItemSuppliersQueryVariables = Exact<{
  orderItemId: Scalars['ID'];
}>;


export type OrderItemSuppliersQuery = (
  { __typename?: 'Query' }
  & { orderItemSuppliers?: Maybe<Array<(
    { __typename?: 'Supplier' }
    & SupplierPropsFragment
  )>> }
);

export type DeleteOrderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteOrderMutation = (
  { __typename?: 'Mutation' }
  & { deleteOrder?: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'id'>
  )> }
);

export type RefreshOrdersMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshOrdersMutation = (
  { __typename?: 'Mutation' }
  & { refreshStats: (
    { __typename?: 'RefreshStatsResponse' }
    & Pick<RefreshStatsResponse, 'status'>
  ) }
);

export type UpdateOrderMutationVariables = Exact<{
  input: OrderInput;
  id: Scalars['ID'];
}>;


export type UpdateOrderMutation = (
  { __typename?: 'Mutation' }
  & { updateOrder?: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'externalId' | 'status' | 'orderCreatedAt'>
  )> }
);

export type UpdateOrdersMutationVariables = Exact<{
  input: BulkUpdateOrderInput;
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type UpdateOrdersMutation = (
  { __typename?: 'Mutation' }
  & { updateOrders?: Maybe<Array<Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'externalId'>
  )>>> }
);

export type OrderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrderQuery = (
  { __typename?: 'Query' }
  & { order?: Maybe<(
    { __typename?: 'Order' }
    & OrderPropsFragment
  )> }
);

export type OrderByShippingNumberQueryVariables = Exact<{
  shippingNumber: Scalars['String'];
}>;


export type OrderByShippingNumberQuery = (
  { __typename?: 'Query' }
  & { orderByShippingNumber?: Maybe<Array<(
    { __typename?: 'Order' }
    & OrderPropsFragment
  )>> }
);

export type OrdersQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  filter?: Maybe<OrderFilter>;
  sort?: Maybe<OrderSort>;
}>;


export type OrdersQuery = (
  { __typename?: 'Query' }
  & { orders?: Maybe<(
    { __typename?: 'OrderPaginator' }
    & { paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & PaginationFragment
    ), data: Array<(
      { __typename?: 'Order' }
      & Pick<Order, 'id' | 'externalId' | 'isPaid' | 'isInvoiced' | 'status' | 'orderCreatedAt' | 'orderTotal'>
      & { customer?: Maybe<(
        { __typename?: 'Customer' }
        & Pick<Customer, 'name'>
      )>, marketplace: (
        { __typename?: 'Marketplace' }
        & Pick<Marketplace, 'name' | 'slug'>
        & { currency: (
          { __typename?: 'Currency' }
          & Pick<Currency, 'slug' | 'prefix' | 'suffix'>
        ) }
      ) }
    )> }
  )> }
);

export type CommentMutationVariables = Exact<{
  input: CommentInput;
}>;


export type CommentMutation = (
  { __typename?: 'Mutation' }
  & { createComment?: Maybe<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'body'>
  )> }
);

export type CreatePostMutationVariables = Exact<{
  input: PostInput;
}>;


export type CreatePostMutation = (
  { __typename?: 'Mutation' }
  & { createPost?: Maybe<(
    { __typename?: 'Post' }
    & Pick<Post, 'id' | 'body'>
  )> }
);

export type DeletePostMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePostMutation = (
  { __typename?: 'Mutation' }
  & { deletePost?: Maybe<(
    { __typename?: 'Post' }
    & Pick<Post, 'id'>
  )> }
);

export type LikeMutationVariables = Exact<{
  input: LikeInput;
}>;


export type LikeMutation = (
  { __typename?: 'Mutation' }
  & { createLike?: Maybe<(
    { __typename?: 'Like' }
    & Pick<Like, 'id'>
  )> }
);

export type UnlikeMutationVariables = Exact<{
  input: LikeInput;
}>;


export type UnlikeMutation = (
  { __typename?: 'Mutation' }
  & { deleteLike?: Maybe<(
    { __typename?: 'Like' }
    & Pick<Like, 'id'>
  )> }
);

export type PostsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type PostsQuery = (
  { __typename?: 'Query' }
  & { posts?: Maybe<(
    { __typename?: 'PostPaginator' }
    & { data: Array<(
      { __typename?: 'Post' }
      & Pick<Post, 'id' | 'isLiked' | 'body' | 'createdAt'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name'>
        & { image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'url'>
        )> }
      ), likes?: Maybe<Array<(
        { __typename?: 'Like' }
        & Pick<Like, 'id'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'name'>
        ) }
      )>>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'url'>
      )>, comments?: Maybe<Array<(
        { __typename?: 'Comment' }
        & Pick<Comment, 'id' | 'isLiked' | 'body' | 'createdAt'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'name'>
          & { image?: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'url'>
          )> }
        ), image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'url'>
        )>, likes?: Maybe<Array<(
          { __typename?: 'Like' }
          & Pick<Like, 'id'>
          & { user: (
            { __typename?: 'User' }
            & Pick<User, 'name'>
          ) }
        )>> }
      )>> }
    )> }
  )> }
);

export type DeleteProductMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteProductMutation = (
  { __typename?: 'Mutation' }
  & { deleteProduct?: Maybe<(
    { __typename?: 'Product' }
    & Pick<Product, 'name'>
  )> }
);

export type DeleteProductsMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DeleteProductsMutation = (
  { __typename?: 'Mutation' }
  & { deleteProducts?: Maybe<Array<Maybe<(
    { __typename?: 'Product' }
    & Pick<Product, 'name'>
  )>>> }
);

export type CreateImportMutationVariables = Exact<{
  input: CreateImportInput;
}>;


export type CreateImportMutation = (
  { __typename?: 'Mutation' }
  & { createImport: (
    { __typename?: 'Import' }
    & Pick<Import, 'id' | 'uploadFileUrl' | 'status' | 'priceMultiplier' | 'currencyMultiplier' | 'vat'>
    & { supplier?: Maybe<(
      { __typename?: 'Supplier' }
      & Pick<Supplier, 'id'>
    )>, marketplace: (
      { __typename?: 'Marketplace' }
      & Pick<Marketplace, 'slug' | 'name'>
      & { currency: (
        { __typename?: 'Currency' }
        & Pick<Currency, 'slug' | 'prefix' | 'suffix'>
      ) }
    ), properties?: Maybe<Array<(
      { __typename?: 'ImportProperty' }
      & Pick<ImportProperty, 'from' | 'to'>
    )>> }
  ) }
);

export type StartImportMutationVariables = Exact<{
  input: StartImportInput;
  id: Scalars['ID'];
}>;


export type StartImportMutation = (
  { __typename?: 'Mutation' }
  & { startImport: (
    { __typename?: 'ImportResponse' }
    & Pick<ImportResponse, 'status' | 'message'>
  ) }
);

export type GenerateFileForImportMutationVariables = Exact<{
  input: GenerateFileForImportInput;
  id: Scalars['ID'];
}>;


export type GenerateFileForImportMutation = (
  { __typename?: 'Mutation' }
  & { generateFileForImport: (
    { __typename?: 'ImportResponse' }
    & Pick<ImportResponse, 'status' | 'message'>
  ) }
);

export type StartPlainImportMutationVariables = Exact<{
  input: StartPlainImportInput;
}>;


export type StartPlainImportMutation = (
  { __typename?: 'Mutation' }
  & { startPlainImport: (
    { __typename?: 'ImportResponse' }
    & Pick<ImportResponse, 'status' | 'message'>
  ) }
);

export type UpdateProductMutationVariables = Exact<{
  input: ProductInput;
  id: Scalars['ID'];
}>;


export type UpdateProductMutation = (
  { __typename?: 'Mutation' }
  & { updateProduct?: Maybe<(
    { __typename?: 'Product' }
    & Pick<Product, 'name'>
  )> }
);

export type ImportsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type ImportsQuery = (
  { __typename?: 'Query' }
  & { imports?: Maybe<(
    { __typename?: 'ImportPaginator' }
    & { paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & PaginationFragment
    ), data: Array<(
      { __typename?: 'Import' }
      & Pick<Import, 'id' | 'uploadFileUrl' | 'lang' | 'status' | 'priceMultiplier' | 'vat' | 'filtered' | 'productCount' | 'duplicationCount' | 'currencyMultiplier' | 'updatedCount' | 'eanMissingCount' | 'deactivatedCount' | 'marketplaceResponse' | 'allCount' | 'errorMessage' | 'createdAt' | 'newMissedProductsUrl'>
      & { supplier?: Maybe<(
        { __typename?: 'Supplier' }
        & Pick<Supplier, 'name'>
      )>, marketplace: (
        { __typename?: 'Marketplace' }
        & Pick<Marketplace, 'name'>
      ) }
    )> }
  )> }
);

export type ShowImportPropertiesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ShowImportPropertiesQuery = (
  { __typename?: 'Query' }
  & { showImportProperties: Array<(
    { __typename?: 'ImportPropertyConfiguration' }
    & Pick<ImportPropertyConfiguration, 'key' | 'readable' | 'optional'>
  )> }
);

export type ShowImportQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type ShowImportQuery = (
  { __typename?: 'Query' }
  & { showImport?: Maybe<(
    { __typename?: 'Import' }
    & Pick<Import, 'id' | 'uploadFileUrl' | 'status' | 'productCount' | 'duplicationCount' | 'updatedCount' | 'eanMissingCount' | 'deactivatedCount' | 'marketplaceResponse' | 'allCount' | 'errorMessage' | 'filtered' | 'lang' | 'createdAt'>
  )> }
);

export type MarketplacesQueryVariables = Exact<{ [key: string]: never; }>;


export type MarketplacesQuery = (
  { __typename?: 'Query' }
  & { marketplaces: Array<(
    { __typename?: 'Marketplace' }
    & Pick<Marketplace, 'slug' | 'name'>
    & { currency: (
      { __typename?: 'Currency' }
      & Pick<Currency, 'slug' | 'prefix' | 'suffix'>
    ) }
  )> }
);

export type ProductQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProductQuery = (
  { __typename?: 'Query' }
  & { product?: Maybe<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'ean' | 'name' | 'pictureUrl' | 'discount' | 'country' | 'shortDescription' | 'description' | 'price' | 'minPrice' | 'minDeliveryTime' | 'maxDeliveryTime' | 'category'>
    & { marketplace: (
      { __typename?: 'Marketplace' }
      & { currency: (
        { __typename?: 'Currency' }
        & Pick<Currency, 'suffix' | 'prefix'>
      ) }
    ) }
  )> }
);

export type ProductCategoriesQueryVariables = Exact<{
  searchTerm: Scalars['String'];
}>;


export type ProductCategoriesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'productCategories'>
);

export type ProductsQueryVariables = Exact<{
  slug: MarketplaceSlug;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  filter?: Maybe<ProductFilter>;
}>;


export type ProductsQuery = (
  { __typename?: 'Query' }
  & { products?: Maybe<(
    { __typename?: 'ProductPaginator' }
    & { paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & PaginationFragment
    ), data: Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'createdAt' | 'ean' | 'name' | 'category' | 'price' | 'minPrice' | 'minDeliveryTime' | 'maxDeliveryTime' | 'description'>
      & { marketplace: (
        { __typename?: 'Marketplace' }
        & Pick<Marketplace, 'name'>
        & { currency: (
          { __typename?: 'Currency' }
          & Pick<Currency, 'prefix' | 'suffix'>
        ) }
      ) }
    )> }
  )> }
);

export type ChartDataFragment = (
  { __typename?: 'Chart' }
  & { sum?: Maybe<Array<(
    { __typename?: 'Stat' }
    & Pick<Stat, 'value' | 'unit'>
  )>>, items?: Maybe<Array<(
    { __typename?: 'DatedStat' }
    & Pick<DatedStat, 'date' | 'size'>
    & { values?: Maybe<Array<(
      { __typename?: 'Stat' }
      & Pick<Stat, 'value' | 'unit'>
    )>> }
  )>> }
);

export type CurrentAndPreviousFragment = (
  { __typename?: 'PeriodChart' }
  & { current: (
    { __typename?: 'Chart' }
    & ChartDataFragment
  ), prev: (
    { __typename?: 'Chart' }
    & ChartDataFragment
  ) }
);

export type InitialStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type InitialStatsQuery = (
  { __typename?: 'Query' }
  & { ordersCountCurrentStat: (
    { __typename?: 'Chart' }
    & ChartDataFragment
  ), revenueCurrentStat: (
    { __typename?: 'Chart' }
    & ChartDataFragment
  ), revenueYearlyStat: (
    { __typename?: 'PeriodChart' }
    & CurrentAndPreviousFragment
  ) }
);

export type RevenueYearlyStatQueryVariables = Exact<{
  year?: Maybe<Scalars['Int']>;
}>;


export type RevenueYearlyStatQuery = (
  { __typename?: 'Query' }
  & { revenueYearlyStat: (
    { __typename?: 'PeriodChart' }
    & CurrentAndPreviousFragment
  ) }
);

export type RevenueMonthlyStatQueryVariables = Exact<{
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
}>;


export type RevenueMonthlyStatQuery = (
  { __typename?: 'Query' }
  & { revenueMonthlyStat: (
    { __typename?: 'PeriodChart' }
    & CurrentAndPreviousFragment
  ) }
);

export type RevenueWeeklyStatQueryVariables = Exact<{
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
}>;


export type RevenueWeeklyStatQuery = (
  { __typename?: 'Query' }
  & { revenueWeeklyStat: (
    { __typename?: 'PeriodChart' }
    & CurrentAndPreviousFragment
  ) }
);

export type AddOrderItemToStockMutationVariables = Exact<{
  input: OrderItemToStockInput;
  id: Scalars['ID'];
}>;


export type AddOrderItemToStockMutation = (
  { __typename?: 'Mutation' }
  & { addOrderItemToStock?: Maybe<(
    { __typename?: 'OrderItem' }
    & Pick<OrderItem, 'name'>
  )> }
);

export type CreateStockMutationVariables = Exact<{
  input: CreateStockInput;
}>;


export type CreateStockMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createStocks'>
);

export type DeleteStockMutationVariables = Exact<{
  ean: Scalars['String'];
}>;


export type DeleteStockMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteStock'>
);

export type DeleteStocksMutationVariables = Exact<{
  eans: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteStocksMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteStocks'>
);

export type UpdateStockMutationVariables = Exact<{
  ean: Scalars['String'];
  input: UpdateStockInput;
}>;


export type UpdateStockMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateStocks'>
);

export type StocksQueryVariables = Exact<{
  filter?: Maybe<StockFilter>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type StocksQuery = (
  { __typename?: 'Query' }
  & { stocks?: Maybe<(
    { __typename?: 'StockPaginator' }
    & { paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & PaginationFragment
    ), data: Array<(
      { __typename?: 'Stock' }
      & Pick<Stock, 'ean' | 'names' | 'categories' | 'quantity' | 'lastUpdatedAt'>
    )> }
  )> }
);

export type CreateSupplierMutationVariables = Exact<{
  input: CreateSupplierInput;
}>;


export type CreateSupplierMutation = (
  { __typename?: 'Mutation' }
  & { createSupplier: (
    { __typename?: 'Supplier' }
    & SupplierPropsFragment
  ) }
);

export type DeleteSupplierMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteSupplierMutation = (
  { __typename?: 'Mutation' }
  & { deleteSupplier: (
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'name'>
  ) }
);

export type UpdateSupplierMutationVariables = Exact<{
  input: UpdateSupplierInput;
  id: Scalars['ID'];
}>;


export type UpdateSupplierMutation = (
  { __typename?: 'Mutation' }
  & { updateSupplier: (
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'name'>
  ) }
);

export type UpdateSupplierConfigurationMutationVariables = Exact<{
  input: SupplierConfigurationInput;
  supplierId: Scalars['ID'];
  marketplace: MarketplaceSlug;
}>;


export type UpdateSupplierConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { updateSupplierConfiguration: (
    { __typename?: 'Supplier' }
    & SupplierPropsFragment
  ) }
);

export type MarketplacePropertiesQueryVariables = Exact<{
  marketplace: MarketplaceSlug;
}>;


export type MarketplacePropertiesQuery = (
  { __typename?: 'Query' }
  & { showMarketplaceProperties: Array<(
    { __typename?: 'MarketplaceProperty' }
    & Pick<MarketplaceProperty, 'key' | 'readable' | 'optional'>
  )> }
);

export type SuppliersQueryVariables = Exact<{ [key: string]: never; }>;


export type SuppliersQuery = (
  { __typename?: 'Query' }
  & { suppliers?: Maybe<Array<(
    { __typename?: 'Supplier' }
    & SupplierPropsFragment
  )>> }
);

export type SupplierQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SupplierQuery = (
  { __typename?: 'Query' }
  & { supplier?: Maybe<(
    { __typename?: 'Supplier' }
    & SupplierPropsFragment
  )> }
);

export const EventFragmentDoc = gql`
    fragment Event on Event {
  id
  title
  description
  from
  to
  userClass
}
    `;
export const OrderPropsFragmentDoc = gql`
    fragment OrderProps on Order {
  id
  externalId
  isPaid
  isInvoiced
  items {
    id
    ean
    name
    status
    quantity
    product {
      id
      name
    }
    supplier {
      id
      name
    }
    priceGross
    order {
      marketplace {
        slug
        name
        currency {
          prefix
          suffix
        }
      }
    }
  }
  orderCreatedAt
  marketplace {
    slug
    name
    currency {
      slug
      prefix
      suffix
    }
  }
  note
  billingAddress {
    name
    street
    zip
    city
    country
  }
  customer {
    email
    name
    phone
  }
  shippingAddress {
    name
    city
    zip
    country
    street
  }
  itemsTotal
  vatTotal
  orderTotal
  status
  paymentType
  shippingFeeNet
  shippingNumber
  shippingProvider
}
    `;
export const PaginationFragmentDoc = gql`
    fragment Pagination on PaginatorInfo {
  count
  currentPage
  firstItem
  hasMorePages
  lastItem
  lastPage
  perPage
  total
}
    `;
export const SupplierPropsFragmentDoc = gql`
    fragment SupplierProps on Supplier {
  id
  name
  url
  configurations {
    marketplace {
      slug
    }
    properties {
      from
      to
    }
  }
}
    `;
export const UserPropsFragmentDoc = gql`
    fragment UserProps on User {
  id
  name
  email
  roles
  classes
  image {
    url
  }
}
    `;
export const ChartDataFragmentDoc = gql`
    fragment ChartData on Chart {
  sum {
    value
    unit
  }
  items {
    date
    values {
      value
      unit
    }
    size
  }
}
    `;
export const CurrentAndPreviousFragmentDoc = gql`
    fragment CurrentAndPrevious on PeriodChart {
  current {
    ...ChartData
  }
  prev {
    ...ChartData
  }
}
    ${ChartDataFragmentDoc}`;
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    name
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: ID!) {
  deleteUser(id: $id) {
    id
    name
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($input: ForgotPasswordInput!) {
  forgotPassword(input: $input) {
    status
    message
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const LoginDocument = gql`
    mutation Login($input: LoginInput) {
  login(input: $input) {
    token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogOutDocument = gql`
    mutation LogOut {
  logout {
    message
    status
  }
}
    `;
export type LogOutMutationFn = Apollo.MutationFunction<LogOutMutation, LogOutMutationVariables>;

/**
 * __useLogOutMutation__
 *
 * To run a mutation, you first call `useLogOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logOutMutation, { data, loading, error }] = useLogOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogOutMutation(baseOptions?: Apollo.MutationHookOptions<LogOutMutation, LogOutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogOutMutation, LogOutMutationVariables>(LogOutDocument, options);
      }
export type LogOutMutationHookResult = ReturnType<typeof useLogOutMutation>;
export type LogOutMutationResult = Apollo.MutationResult<LogOutMutation>;
export type LogOutMutationOptions = Apollo.BaseMutationOptions<LogOutMutation, LogOutMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    message
    status
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const UpdateMeDocument = gql`
    mutation UpdateMe($input: UpdateMeInput!) {
  updateMe(input: $input) {
    id
  }
}
    `;
export type UpdateMeMutationFn = Apollo.MutationFunction<UpdateMeMutation, UpdateMeMutationVariables>;

/**
 * __useUpdateMeMutation__
 *
 * To run a mutation, you first call `useUpdateMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeMutation, { data, loading, error }] = useUpdateMeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMeMutation, UpdateMeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMeMutation, UpdateMeMutationVariables>(UpdateMeDocument, options);
      }
export type UpdateMeMutationHookResult = ReturnType<typeof useUpdateMeMutation>;
export type UpdateMeMutationResult = Apollo.MutationResult<UpdateMeMutation>;
export type UpdateMeMutationOptions = Apollo.BaseMutationOptions<UpdateMeMutation, UpdateMeMutationVariables>;
export const UpdatePasswordDocument = gql`
    mutation UpdatePassword($input: UpdatePasswordInput!) {
  updatePassword(input: $input) {
    status
  }
}
    `;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, options);
      }
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!, $id: ID!) {
  updateUser(input: $input, id: $id) {
    id
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    name
    email
    roles
    classes
    image {
      url
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UsersDocument = gql`
    query Users($first: Int = 15, $page: Int) {
  users(first: $first, page: $page) {
    paginatorInfo {
      ...Pagination
    }
    data {
      ...UserProps
    }
  }
}
    ${PaginationFragmentDoc}
${UserPropsFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const UserDocument = gql`
    query User($id: ID!) {
  user(id: $id) {
    ...UserProps
  }
}
    ${UserPropsFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const CreateEventDocument = gql`
    mutation CreateEvent($input: CreateEventInput!) {
  createEvent(input: $input) {
    title
  }
}
    `;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, options);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const DeleteEventDocument = gql`
    mutation DeleteEvent($id: ID!) {
  deleteEvent(id: $id) {
    title
  }
}
    `;
export type DeleteEventMutationFn = Apollo.MutationFunction<DeleteEventMutation, DeleteEventMutationVariables>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEventMutation, DeleteEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(DeleteEventDocument, options);
      }
export type DeleteEventMutationHookResult = ReturnType<typeof useDeleteEventMutation>;
export type DeleteEventMutationResult = Apollo.MutationResult<DeleteEventMutation>;
export type DeleteEventMutationOptions = Apollo.BaseMutationOptions<DeleteEventMutation, DeleteEventMutationVariables>;
export const UpdateEventDocument = gql`
    mutation UpdateEvent($input: UpdateEventInput!, $id: ID!) {
  updateEvent(input: $input, id: $id) {
    ...Event
  }
}
    ${EventFragmentDoc}`;
export type UpdateEventMutationFn = Apollo.MutationFunction<UpdateEventMutation, UpdateEventMutationVariables>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument, options);
      }
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = Apollo.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<UpdateEventMutation, UpdateEventMutationVariables>;
export const EventDocument = gql`
    query Event($id: ID!) {
  event(id: $id) {
    ...Event
  }
}
    ${EventFragmentDoc}`;

/**
 * __useEventQuery__
 *
 * To run a query within a React component, call `useEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventQuery(baseOptions: Apollo.QueryHookOptions<EventQuery, EventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventQuery, EventQueryVariables>(EventDocument, options);
      }
export function useEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventQuery, EventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventQuery, EventQueryVariables>(EventDocument, options);
        }
export type EventQueryHookResult = ReturnType<typeof useEventQuery>;
export type EventLazyQueryHookResult = ReturnType<typeof useEventLazyQuery>;
export type EventQueryResult = Apollo.QueryResult<EventQuery, EventQueryVariables>;
export const EventsDocument = gql`
    query Events($filter: EventFilter!) {
  events(filter: $filter) {
    ...Event
  }
}
    ${EventFragmentDoc}`;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEventsQuery(baseOptions: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
      }
export function useEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
        }
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = Apollo.QueryResult<EventsQuery, EventsQueryVariables>;
export const EventsTodayDocument = gql`
    query EventsToday {
  eventsToday {
    id
    title
    from
    to
  }
}
    `;

/**
 * __useEventsTodayQuery__
 *
 * To run a query within a React component, call `useEventsTodayQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsTodayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsTodayQuery({
 *   variables: {
 *   },
 * });
 */
export function useEventsTodayQuery(baseOptions?: Apollo.QueryHookOptions<EventsTodayQuery, EventsTodayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsTodayQuery, EventsTodayQueryVariables>(EventsTodayDocument, options);
      }
export function useEventsTodayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsTodayQuery, EventsTodayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsTodayQuery, EventsTodayQueryVariables>(EventsTodayDocument, options);
        }
export type EventsTodayQueryHookResult = ReturnType<typeof useEventsTodayQuery>;
export type EventsTodayLazyQueryHookResult = ReturnType<typeof useEventsTodayLazyQuery>;
export type EventsTodayQueryResult = Apollo.QueryResult<EventsTodayQuery, EventsTodayQueryVariables>;
export const CreateEanDocument = gql`
    mutation CreateEan($input: EanCodeInput!) {
  createEanCode(input: $input) {
    id
    ean
    type
  }
}
    `;
export type CreateEanMutationFn = Apollo.MutationFunction<CreateEanMutation, CreateEanMutationVariables>;

/**
 * __useCreateEanMutation__
 *
 * To run a mutation, you first call `useCreateEanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEanMutation, { data, loading, error }] = useCreateEanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEanMutation(baseOptions?: Apollo.MutationHookOptions<CreateEanMutation, CreateEanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEanMutation, CreateEanMutationVariables>(CreateEanDocument, options);
      }
export type CreateEanMutationHookResult = ReturnType<typeof useCreateEanMutation>;
export type CreateEanMutationResult = Apollo.MutationResult<CreateEanMutation>;
export type CreateEanMutationOptions = Apollo.BaseMutationOptions<CreateEanMutation, CreateEanMutationVariables>;
export const DeleteEanDocument = gql`
    mutation DeleteEan($id: ID!) {
  deleteEanCode(id: $id) {
    ean
  }
}
    `;
export type DeleteEanMutationFn = Apollo.MutationFunction<DeleteEanMutation, DeleteEanMutationVariables>;

/**
 * __useDeleteEanMutation__
 *
 * To run a mutation, you first call `useDeleteEanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEanMutation, { data, loading, error }] = useDeleteEanMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEanMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEanMutation, DeleteEanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEanMutation, DeleteEanMutationVariables>(DeleteEanDocument, options);
      }
export type DeleteEanMutationHookResult = ReturnType<typeof useDeleteEanMutation>;
export type DeleteEanMutationResult = Apollo.MutationResult<DeleteEanMutation>;
export type DeleteEanMutationOptions = Apollo.BaseMutationOptions<DeleteEanMutation, DeleteEanMutationVariables>;
export const DeleteEansDocument = gql`
    mutation DeleteEans($ids: [ID!]!) {
  deleteEanCodes(ids: $ids) {
    ean
  }
}
    `;
export type DeleteEansMutationFn = Apollo.MutationFunction<DeleteEansMutation, DeleteEansMutationVariables>;

/**
 * __useDeleteEansMutation__
 *
 * To run a mutation, you first call `useDeleteEansMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEansMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEansMutation, { data, loading, error }] = useDeleteEansMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteEansMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEansMutation, DeleteEansMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEansMutation, DeleteEansMutationVariables>(DeleteEansDocument, options);
      }
export type DeleteEansMutationHookResult = ReturnType<typeof useDeleteEansMutation>;
export type DeleteEansMutationResult = Apollo.MutationResult<DeleteEansMutation>;
export type DeleteEansMutationOptions = Apollo.BaseMutationOptions<DeleteEansMutation, DeleteEansMutationVariables>;
export const UpdateEanDocument = gql`
    mutation UpdateEan($input: EanCodeInput!, $id: ID!) {
  updateEanCode(input: $input, id: $id) {
    id
    ean
    type
  }
}
    `;
export type UpdateEanMutationFn = Apollo.MutationFunction<UpdateEanMutation, UpdateEanMutationVariables>;

/**
 * __useUpdateEanMutation__
 *
 * To run a mutation, you first call `useUpdateEanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEanMutation, { data, loading, error }] = useUpdateEanMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateEanMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEanMutation, UpdateEanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEanMutation, UpdateEanMutationVariables>(UpdateEanDocument, options);
      }
export type UpdateEanMutationHookResult = ReturnType<typeof useUpdateEanMutation>;
export type UpdateEanMutationResult = Apollo.MutationResult<UpdateEanMutation>;
export type UpdateEanMutationOptions = Apollo.BaseMutationOptions<UpdateEanMutation, UpdateEanMutationVariables>;
export const EanCodesDocument = gql`
    query EanCodes($filter: EanCodeFilter, $first: Int = 15, $page: Int) {
  eanCodes(filter: $filter, first: $first, page: $page) {
    paginatorInfo {
      ...Pagination
    }
    data {
      id
      ean
      type
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useEanCodesQuery__
 *
 * To run a query within a React component, call `useEanCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEanCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEanCodesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useEanCodesQuery(baseOptions?: Apollo.QueryHookOptions<EanCodesQuery, EanCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EanCodesQuery, EanCodesQueryVariables>(EanCodesDocument, options);
      }
export function useEanCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EanCodesQuery, EanCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EanCodesQuery, EanCodesQueryVariables>(EanCodesDocument, options);
        }
export type EanCodesQueryHookResult = ReturnType<typeof useEanCodesQuery>;
export type EanCodesLazyQueryHookResult = ReturnType<typeof useEanCodesLazyQuery>;
export type EanCodesQueryResult = Apollo.QueryResult<EanCodesQuery, EanCodesQueryVariables>;
export const UploadImageDocument = gql`
    mutation UploadImage($file: Upload!) {
  uploadImage(file: $file) {
    id
  }
}
    `;
export type UploadImageMutationFn = Apollo.MutationFunction<UploadImageMutation, UploadImageMutationVariables>;

/**
 * __useUploadImageMutation__
 *
 * To run a mutation, you first call `useUploadImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImageMutation, { data, loading, error }] = useUploadImageMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadImageMutation(baseOptions?: Apollo.MutationHookOptions<UploadImageMutation, UploadImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadImageMutation, UploadImageMutationVariables>(UploadImageDocument, options);
      }
export type UploadImageMutationHookResult = ReturnType<typeof useUploadImageMutation>;
export type UploadImageMutationResult = Apollo.MutationResult<UploadImageMutation>;
export type UploadImageMutationOptions = Apollo.BaseMutationOptions<UploadImageMutation, UploadImageMutationVariables>;
export const CreateIncomingInvoiceDocument = gql`
    mutation CreateIncomingInvoice($input: CreateIncomingInvoiceInput) {
  createIncomingInvoice(input: $input) {
    invoiceNumber
  }
}
    `;
export type CreateIncomingInvoiceMutationFn = Apollo.MutationFunction<CreateIncomingInvoiceMutation, CreateIncomingInvoiceMutationVariables>;

/**
 * __useCreateIncomingInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateIncomingInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIncomingInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIncomingInvoiceMutation, { data, loading, error }] = useCreateIncomingInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIncomingInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateIncomingInvoiceMutation, CreateIncomingInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateIncomingInvoiceMutation, CreateIncomingInvoiceMutationVariables>(CreateIncomingInvoiceDocument, options);
      }
export type CreateIncomingInvoiceMutationHookResult = ReturnType<typeof useCreateIncomingInvoiceMutation>;
export type CreateIncomingInvoiceMutationResult = Apollo.MutationResult<CreateIncomingInvoiceMutation>;
export type CreateIncomingInvoiceMutationOptions = Apollo.BaseMutationOptions<CreateIncomingInvoiceMutation, CreateIncomingInvoiceMutationVariables>;
export const DeleteIncomingInvoiceDocument = gql`
    mutation DeleteIncomingInvoice($id: ID!) {
  deleteIncomingInvoice(id: $id) {
    invoiceNumber
  }
}
    `;
export type DeleteIncomingInvoiceMutationFn = Apollo.MutationFunction<DeleteIncomingInvoiceMutation, DeleteIncomingInvoiceMutationVariables>;

/**
 * __useDeleteIncomingInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteIncomingInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIncomingInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIncomingInvoiceMutation, { data, loading, error }] = useDeleteIncomingInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteIncomingInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteIncomingInvoiceMutation, DeleteIncomingInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteIncomingInvoiceMutation, DeleteIncomingInvoiceMutationVariables>(DeleteIncomingInvoiceDocument, options);
      }
export type DeleteIncomingInvoiceMutationHookResult = ReturnType<typeof useDeleteIncomingInvoiceMutation>;
export type DeleteIncomingInvoiceMutationResult = Apollo.MutationResult<DeleteIncomingInvoiceMutation>;
export type DeleteIncomingInvoiceMutationOptions = Apollo.BaseMutationOptions<DeleteIncomingInvoiceMutation, DeleteIncomingInvoiceMutationVariables>;
export const DeleteIncomingInvoicesDocument = gql`
    mutation DeleteIncomingInvoices($ids: [ID!]!) {
  deleteIncomingInvoices(ids: $ids) {
    invoiceNumber
  }
}
    `;
export type DeleteIncomingInvoicesMutationFn = Apollo.MutationFunction<DeleteIncomingInvoicesMutation, DeleteIncomingInvoicesMutationVariables>;

/**
 * __useDeleteIncomingInvoicesMutation__
 *
 * To run a mutation, you first call `useDeleteIncomingInvoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIncomingInvoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIncomingInvoicesMutation, { data, loading, error }] = useDeleteIncomingInvoicesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteIncomingInvoicesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteIncomingInvoicesMutation, DeleteIncomingInvoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteIncomingInvoicesMutation, DeleteIncomingInvoicesMutationVariables>(DeleteIncomingInvoicesDocument, options);
      }
export type DeleteIncomingInvoicesMutationHookResult = ReturnType<typeof useDeleteIncomingInvoicesMutation>;
export type DeleteIncomingInvoicesMutationResult = Apollo.MutationResult<DeleteIncomingInvoicesMutation>;
export type DeleteIncomingInvoicesMutationOptions = Apollo.BaseMutationOptions<DeleteIncomingInvoicesMutation, DeleteIncomingInvoicesMutationVariables>;
export const DeleteOutgoingInvoiceDocument = gql`
    mutation DeleteOutgoingInvoice($id: ID!) {
  deleteOutgoingInvoice(id: $id) {
    id
  }
}
    `;
export type DeleteOutgoingInvoiceMutationFn = Apollo.MutationFunction<DeleteOutgoingInvoiceMutation, DeleteOutgoingInvoiceMutationVariables>;

/**
 * __useDeleteOutgoingInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteOutgoingInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOutgoingInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOutgoingInvoiceMutation, { data, loading, error }] = useDeleteOutgoingInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOutgoingInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOutgoingInvoiceMutation, DeleteOutgoingInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOutgoingInvoiceMutation, DeleteOutgoingInvoiceMutationVariables>(DeleteOutgoingInvoiceDocument, options);
      }
export type DeleteOutgoingInvoiceMutationHookResult = ReturnType<typeof useDeleteOutgoingInvoiceMutation>;
export type DeleteOutgoingInvoiceMutationResult = Apollo.MutationResult<DeleteOutgoingInvoiceMutation>;
export type DeleteOutgoingInvoiceMutationOptions = Apollo.BaseMutationOptions<DeleteOutgoingInvoiceMutation, DeleteOutgoingInvoiceMutationVariables>;
export const DeleteOutgoingInvoicesDocument = gql`
    mutation DeleteOutgoingInvoices($ids: [ID!]!) {
  deleteOutgoingInvoices(ids: $ids) {
    id
  }
}
    `;
export type DeleteOutgoingInvoicesMutationFn = Apollo.MutationFunction<DeleteOutgoingInvoicesMutation, DeleteOutgoingInvoicesMutationVariables>;

/**
 * __useDeleteOutgoingInvoicesMutation__
 *
 * To run a mutation, you first call `useDeleteOutgoingInvoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOutgoingInvoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOutgoingInvoicesMutation, { data, loading, error }] = useDeleteOutgoingInvoicesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteOutgoingInvoicesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOutgoingInvoicesMutation, DeleteOutgoingInvoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOutgoingInvoicesMutation, DeleteOutgoingInvoicesMutationVariables>(DeleteOutgoingInvoicesDocument, options);
      }
export type DeleteOutgoingInvoicesMutationHookResult = ReturnType<typeof useDeleteOutgoingInvoicesMutation>;
export type DeleteOutgoingInvoicesMutationResult = Apollo.MutationResult<DeleteOutgoingInvoicesMutation>;
export type DeleteOutgoingInvoicesMutationOptions = Apollo.BaseMutationOptions<DeleteOutgoingInvoicesMutation, DeleteOutgoingInvoicesMutationVariables>;
export const UpdateIncomingInvoiceDocument = gql`
    mutation UpdateIncomingInvoice($id: ID!, $input: UpdateIncomingInvoiceInput!) {
  updateIncomingInvoice(id: $id, input: $input) {
    invoiceNumber
    status
  }
}
    `;
export type UpdateIncomingInvoiceMutationFn = Apollo.MutationFunction<UpdateIncomingInvoiceMutation, UpdateIncomingInvoiceMutationVariables>;

/**
 * __useUpdateIncomingInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateIncomingInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIncomingInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIncomingInvoiceMutation, { data, loading, error }] = useUpdateIncomingInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIncomingInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIncomingInvoiceMutation, UpdateIncomingInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIncomingInvoiceMutation, UpdateIncomingInvoiceMutationVariables>(UpdateIncomingInvoiceDocument, options);
      }
export type UpdateIncomingInvoiceMutationHookResult = ReturnType<typeof useUpdateIncomingInvoiceMutation>;
export type UpdateIncomingInvoiceMutationResult = Apollo.MutationResult<UpdateIncomingInvoiceMutation>;
export type UpdateIncomingInvoiceMutationOptions = Apollo.BaseMutationOptions<UpdateIncomingInvoiceMutation, UpdateIncomingInvoiceMutationVariables>;
export const UpdateOutgoingInvoiceDocument = gql`
    mutation UpdateOutgoingInvoice($id: ID!, $input: OutgoingInvoiceInput!) {
  updateOutgoingInvoice(id: $id, input: $input) {
    status
  }
}
    `;
export type UpdateOutgoingInvoiceMutationFn = Apollo.MutationFunction<UpdateOutgoingInvoiceMutation, UpdateOutgoingInvoiceMutationVariables>;

/**
 * __useUpdateOutgoingInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateOutgoingInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOutgoingInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOutgoingInvoiceMutation, { data, loading, error }] = useUpdateOutgoingInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOutgoingInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOutgoingInvoiceMutation, UpdateOutgoingInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOutgoingInvoiceMutation, UpdateOutgoingInvoiceMutationVariables>(UpdateOutgoingInvoiceDocument, options);
      }
export type UpdateOutgoingInvoiceMutationHookResult = ReturnType<typeof useUpdateOutgoingInvoiceMutation>;
export type UpdateOutgoingInvoiceMutationResult = Apollo.MutationResult<UpdateOutgoingInvoiceMutation>;
export type UpdateOutgoingInvoiceMutationOptions = Apollo.BaseMutationOptions<UpdateOutgoingInvoiceMutation, UpdateOutgoingInvoiceMutationVariables>;
export const IncomingInvoicesDocument = gql`
    query IncomingInvoices($filter: IncomingInvoiceFilter, $first: Int = 15, $page: Int) {
  incomingInvoices(filter: $filter, first: $first, page: $page) {
    paginatorInfo {
      ...Pagination
    }
    data {
      id
      invoiceNumber
      supplier {
        id
        name
        url
      }
      amount
      note
      status
      invoiceCreatedAt
      orderItemGroup {
        id
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useIncomingInvoicesQuery__
 *
 * To run a query within a React component, call `useIncomingInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncomingInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncomingInvoicesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useIncomingInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<IncomingInvoicesQuery, IncomingInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IncomingInvoicesQuery, IncomingInvoicesQueryVariables>(IncomingInvoicesDocument, options);
      }
export function useIncomingInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IncomingInvoicesQuery, IncomingInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IncomingInvoicesQuery, IncomingInvoicesQueryVariables>(IncomingInvoicesDocument, options);
        }
export type IncomingInvoicesQueryHookResult = ReturnType<typeof useIncomingInvoicesQuery>;
export type IncomingInvoicesLazyQueryHookResult = ReturnType<typeof useIncomingInvoicesLazyQuery>;
export type IncomingInvoicesQueryResult = Apollo.QueryResult<IncomingInvoicesQuery, IncomingInvoicesQueryVariables>;
export const OutgoingInvoicesDocument = gql`
    query OutgoingInvoices($filter: OutgoingInvoiceFilter, $first: Int = 15, $page: Int) {
  outgoingInvoices(filter: $filter, first: $first, page: $page) {
    paginatorInfo {
      ...Pagination
    }
    data {
      id
      status
      order {
        id
        orderTotal
        marketplace {
          currency {
            slug
            prefix
            suffix
          }
        }
        customer {
          name
        }
      }
      provider
      invoiceCreatedAt
      url
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useOutgoingInvoicesQuery__
 *
 * To run a query within a React component, call `useOutgoingInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutgoingInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutgoingInvoicesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useOutgoingInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<OutgoingInvoicesQuery, OutgoingInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OutgoingInvoicesQuery, OutgoingInvoicesQueryVariables>(OutgoingInvoicesDocument, options);
      }
export function useOutgoingInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutgoingInvoicesQuery, OutgoingInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OutgoingInvoicesQuery, OutgoingInvoicesQueryVariables>(OutgoingInvoicesDocument, options);
        }
export type OutgoingInvoicesQueryHookResult = ReturnType<typeof useOutgoingInvoicesQuery>;
export type OutgoingInvoicesLazyQueryHookResult = ReturnType<typeof useOutgoingInvoicesLazyQuery>;
export type OutgoingInvoicesQueryResult = Apollo.QueryResult<OutgoingInvoicesQuery, OutgoingInvoicesQueryVariables>;
export const AddOrderItemsToGroupDocument = gql`
    mutation AddOrderItemsToGroup($input: OrderItemToGroupInput!) {
  addOrderItemsToGroup(input: $input) {
    name
  }
}
    `;
export type AddOrderItemsToGroupMutationFn = Apollo.MutationFunction<AddOrderItemsToGroupMutation, AddOrderItemsToGroupMutationVariables>;

/**
 * __useAddOrderItemsToGroupMutation__
 *
 * To run a mutation, you first call `useAddOrderItemsToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrderItemsToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrderItemsToGroupMutation, { data, loading, error }] = useAddOrderItemsToGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOrderItemsToGroupMutation(baseOptions?: Apollo.MutationHookOptions<AddOrderItemsToGroupMutation, AddOrderItemsToGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrderItemsToGroupMutation, AddOrderItemsToGroupMutationVariables>(AddOrderItemsToGroupDocument, options);
      }
export type AddOrderItemsToGroupMutationHookResult = ReturnType<typeof useAddOrderItemsToGroupMutation>;
export type AddOrderItemsToGroupMutationResult = Apollo.MutationResult<AddOrderItemsToGroupMutation>;
export type AddOrderItemsToGroupMutationOptions = Apollo.BaseMutationOptions<AddOrderItemsToGroupMutation, AddOrderItemsToGroupMutationVariables>;
export const CreateOrderItemGroupDocument = gql`
    mutation CreateOrderItemGroup($input: CreateOrderItemGroupInput!) {
  createOrderItemGroup(input: $input) {
    date
    supplier {
      name
    }
  }
}
    `;
export type CreateOrderItemGroupMutationFn = Apollo.MutationFunction<CreateOrderItemGroupMutation, CreateOrderItemGroupMutationVariables>;

/**
 * __useCreateOrderItemGroupMutation__
 *
 * To run a mutation, you first call `useCreateOrderItemGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderItemGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderItemGroupMutation, { data, loading, error }] = useCreateOrderItemGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderItemGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderItemGroupMutation, CreateOrderItemGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderItemGroupMutation, CreateOrderItemGroupMutationVariables>(CreateOrderItemGroupDocument, options);
      }
export type CreateOrderItemGroupMutationHookResult = ReturnType<typeof useCreateOrderItemGroupMutation>;
export type CreateOrderItemGroupMutationResult = Apollo.MutationResult<CreateOrderItemGroupMutation>;
export type CreateOrderItemGroupMutationOptions = Apollo.BaseMutationOptions<CreateOrderItemGroupMutation, CreateOrderItemGroupMutationVariables>;
export const DeleteOrderItemGroupDocument = gql`
    mutation DeleteOrderItemGroup($id: ID!) {
  deleteOrderItemGroup(id: $id) {
    id
  }
}
    `;
export type DeleteOrderItemGroupMutationFn = Apollo.MutationFunction<DeleteOrderItemGroupMutation, DeleteOrderItemGroupMutationVariables>;

/**
 * __useDeleteOrderItemGroupMutation__
 *
 * To run a mutation, you first call `useDeleteOrderItemGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrderItemGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderItemGroupMutation, { data, loading, error }] = useDeleteOrderItemGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrderItemGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrderItemGroupMutation, DeleteOrderItemGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrderItemGroupMutation, DeleteOrderItemGroupMutationVariables>(DeleteOrderItemGroupDocument, options);
      }
export type DeleteOrderItemGroupMutationHookResult = ReturnType<typeof useDeleteOrderItemGroupMutation>;
export type DeleteOrderItemGroupMutationResult = Apollo.MutationResult<DeleteOrderItemGroupMutation>;
export type DeleteOrderItemGroupMutationOptions = Apollo.BaseMutationOptions<DeleteOrderItemGroupMutation, DeleteOrderItemGroupMutationVariables>;
export const RemoveOrderItemsFromGroupDocument = gql`
    mutation RemoveOrderItemsFromGroup($input: OrderItemToGroupInput!) {
  removeOrderItemsFromGroup(input: $input) {
    id
  }
}
    `;
export type RemoveOrderItemsFromGroupMutationFn = Apollo.MutationFunction<RemoveOrderItemsFromGroupMutation, RemoveOrderItemsFromGroupMutationVariables>;

/**
 * __useRemoveOrderItemsFromGroupMutation__
 *
 * To run a mutation, you first call `useRemoveOrderItemsFromGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrderItemsFromGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrderItemsFromGroupMutation, { data, loading, error }] = useRemoveOrderItemsFromGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOrderItemsFromGroupMutation(baseOptions?: Apollo.MutationHookOptions<RemoveOrderItemsFromGroupMutation, RemoveOrderItemsFromGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveOrderItemsFromGroupMutation, RemoveOrderItemsFromGroupMutationVariables>(RemoveOrderItemsFromGroupDocument, options);
      }
export type RemoveOrderItemsFromGroupMutationHookResult = ReturnType<typeof useRemoveOrderItemsFromGroupMutation>;
export type RemoveOrderItemsFromGroupMutationResult = Apollo.MutationResult<RemoveOrderItemsFromGroupMutation>;
export type RemoveOrderItemsFromGroupMutationOptions = Apollo.BaseMutationOptions<RemoveOrderItemsFromGroupMutation, RemoveOrderItemsFromGroupMutationVariables>;
export const UpdateOrderItemGroupDocument = gql`
    mutation UpdateOrderItemGroup($input: UpdateOrderItemGroupInput!, $id: ID!) {
  updateOrderItemGroup(input: $input, id: $id) {
    date
    supplier {
      name
    }
  }
}
    `;
export type UpdateOrderItemGroupMutationFn = Apollo.MutationFunction<UpdateOrderItemGroupMutation, UpdateOrderItemGroupMutationVariables>;

/**
 * __useUpdateOrderItemGroupMutation__
 *
 * To run a mutation, you first call `useUpdateOrderItemGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderItemGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderItemGroupMutation, { data, loading, error }] = useUpdateOrderItemGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateOrderItemGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderItemGroupMutation, UpdateOrderItemGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderItemGroupMutation, UpdateOrderItemGroupMutationVariables>(UpdateOrderItemGroupDocument, options);
      }
export type UpdateOrderItemGroupMutationHookResult = ReturnType<typeof useUpdateOrderItemGroupMutation>;
export type UpdateOrderItemGroupMutationResult = Apollo.MutationResult<UpdateOrderItemGroupMutation>;
export type UpdateOrderItemGroupMutationOptions = Apollo.BaseMutationOptions<UpdateOrderItemGroupMutation, UpdateOrderItemGroupMutationVariables>;
export const OrderItemGroupsDocument = gql`
    query OrderItemGroups($filter: OrderItemGroupFilter, $first: Int = 15, $page: Int) {
  orderItemGroups(page: $page, filter: $filter, first: $first) {
    paginatorInfo {
      ...Pagination
    }
    data {
      id
      date
      supplier {
        id
        name
        url
      }
      items {
        id
        ean
        name
        quantity
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useOrderItemGroupsQuery__
 *
 * To run a query within a React component, call `useOrderItemGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderItemGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderItemGroupsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useOrderItemGroupsQuery(baseOptions?: Apollo.QueryHookOptions<OrderItemGroupsQuery, OrderItemGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderItemGroupsQuery, OrderItemGroupsQueryVariables>(OrderItemGroupsDocument, options);
      }
export function useOrderItemGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderItemGroupsQuery, OrderItemGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderItemGroupsQuery, OrderItemGroupsQueryVariables>(OrderItemGroupsDocument, options);
        }
export type OrderItemGroupsQueryHookResult = ReturnType<typeof useOrderItemGroupsQuery>;
export type OrderItemGroupsLazyQueryHookResult = ReturnType<typeof useOrderItemGroupsLazyQuery>;
export type OrderItemGroupsQueryResult = Apollo.QueryResult<OrderItemGroupsQuery, OrderItemGroupsQueryVariables>;
export const OrderItemGroupsAllDocument = gql`
    query OrderItemGroupsAll($filter: OrderItemGroupAllFilter!) {
  orderItemGroupsAll(filter: $filter) {
    id
    date
    supplier {
      name
    }
    items {
      id
    }
  }
}
    `;

/**
 * __useOrderItemGroupsAllQuery__
 *
 * To run a query within a React component, call `useOrderItemGroupsAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderItemGroupsAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderItemGroupsAllQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrderItemGroupsAllQuery(baseOptions: Apollo.QueryHookOptions<OrderItemGroupsAllQuery, OrderItemGroupsAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderItemGroupsAllQuery, OrderItemGroupsAllQueryVariables>(OrderItemGroupsAllDocument, options);
      }
export function useOrderItemGroupsAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderItemGroupsAllQuery, OrderItemGroupsAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderItemGroupsAllQuery, OrderItemGroupsAllQueryVariables>(OrderItemGroupsAllDocument, options);
        }
export type OrderItemGroupsAllQueryHookResult = ReturnType<typeof useOrderItemGroupsAllQuery>;
export type OrderItemGroupsAllLazyQueryHookResult = ReturnType<typeof useOrderItemGroupsAllLazyQuery>;
export type OrderItemGroupsAllQueryResult = Apollo.QueryResult<OrderItemGroupsAllQuery, OrderItemGroupsAllQueryVariables>;
export const DeleteOrderItemsDocument = gql`
    mutation DeleteOrderItems($id: ID!) {
  deleteOrderItem(id: $id) {
    name
  }
}
    `;
export type DeleteOrderItemsMutationFn = Apollo.MutationFunction<DeleteOrderItemsMutation, DeleteOrderItemsMutationVariables>;

/**
 * __useDeleteOrderItemsMutation__
 *
 * To run a mutation, you first call `useDeleteOrderItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrderItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderItemsMutation, { data, loading, error }] = useDeleteOrderItemsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrderItemsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrderItemsMutation, DeleteOrderItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrderItemsMutation, DeleteOrderItemsMutationVariables>(DeleteOrderItemsDocument, options);
      }
export type DeleteOrderItemsMutationHookResult = ReturnType<typeof useDeleteOrderItemsMutation>;
export type DeleteOrderItemsMutationResult = Apollo.MutationResult<DeleteOrderItemsMutation>;
export type DeleteOrderItemsMutationOptions = Apollo.BaseMutationOptions<DeleteOrderItemsMutation, DeleteOrderItemsMutationVariables>;
export const UpdateOrderItemDocument = gql`
    mutation UpdateOrderItem($input: OrderItemInput!, $id: ID!) {
  updateOrderItem(input: $input, id: $id) {
    name
    supplier {
      name
    }
    status
  }
}
    `;
export type UpdateOrderItemMutationFn = Apollo.MutationFunction<UpdateOrderItemMutation, UpdateOrderItemMutationVariables>;

/**
 * __useUpdateOrderItemMutation__
 *
 * To run a mutation, you first call `useUpdateOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderItemMutation, { data, loading, error }] = useUpdateOrderItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderItemMutation, UpdateOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderItemMutation, UpdateOrderItemMutationVariables>(UpdateOrderItemDocument, options);
      }
export type UpdateOrderItemMutationHookResult = ReturnType<typeof useUpdateOrderItemMutation>;
export type UpdateOrderItemMutationResult = Apollo.MutationResult<UpdateOrderItemMutation>;
export type UpdateOrderItemMutationOptions = Apollo.BaseMutationOptions<UpdateOrderItemMutation, UpdateOrderItemMutationVariables>;
export const OrderItemsDocument = gql`
    query OrderItems($filter: OrderItemFilter, $first: Int = 15, $page: Int) {
  orderItems(filter: $filter, page: $page, first: $first) {
    paginatorInfo {
      ...Pagination
    }
    data {
      id
      order {
        id
        externalId
        orderCreatedAt
        marketplace {
          slug
          name
          currency {
            prefix
            suffix
          }
        }
      }
      name
      product {
        id
        name
      }
      priceNet
      priceGross
      quantity
      ean
      status
      supplier {
        id
        url
        name
      }
      inStock
      canAddToStock
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useOrderItemsQuery__
 *
 * To run a query within a React component, call `useOrderItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderItemsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useOrderItemsQuery(baseOptions?: Apollo.QueryHookOptions<OrderItemsQuery, OrderItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderItemsQuery, OrderItemsQueryVariables>(OrderItemsDocument, options);
      }
export function useOrderItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderItemsQuery, OrderItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderItemsQuery, OrderItemsQueryVariables>(OrderItemsDocument, options);
        }
export type OrderItemsQueryHookResult = ReturnType<typeof useOrderItemsQuery>;
export type OrderItemsLazyQueryHookResult = ReturnType<typeof useOrderItemsLazyQuery>;
export type OrderItemsQueryResult = Apollo.QueryResult<OrderItemsQuery, OrderItemsQueryVariables>;
export const OrderItemSuppliersDocument = gql`
    query OrderItemSuppliers($orderItemId: ID!) {
  orderItemSuppliers(orderItemId: $orderItemId) {
    ...SupplierProps
  }
}
    ${SupplierPropsFragmentDoc}`;

/**
 * __useOrderItemSuppliersQuery__
 *
 * To run a query within a React component, call `useOrderItemSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderItemSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderItemSuppliersQuery({
 *   variables: {
 *      orderItemId: // value for 'orderItemId'
 *   },
 * });
 */
export function useOrderItemSuppliersQuery(baseOptions: Apollo.QueryHookOptions<OrderItemSuppliersQuery, OrderItemSuppliersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderItemSuppliersQuery, OrderItemSuppliersQueryVariables>(OrderItemSuppliersDocument, options);
      }
export function useOrderItemSuppliersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderItemSuppliersQuery, OrderItemSuppliersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderItemSuppliersQuery, OrderItemSuppliersQueryVariables>(OrderItemSuppliersDocument, options);
        }
export type OrderItemSuppliersQueryHookResult = ReturnType<typeof useOrderItemSuppliersQuery>;
export type OrderItemSuppliersLazyQueryHookResult = ReturnType<typeof useOrderItemSuppliersLazyQuery>;
export type OrderItemSuppliersQueryResult = Apollo.QueryResult<OrderItemSuppliersQuery, OrderItemSuppliersQueryVariables>;
export const DeleteOrderDocument = gql`
    mutation DeleteOrder($id: ID!) {
  deleteOrder(id: $id) {
    id
  }
}
    `;
export type DeleteOrderMutationFn = Apollo.MutationFunction<DeleteOrderMutation, DeleteOrderMutationVariables>;

/**
 * __useDeleteOrderMutation__
 *
 * To run a mutation, you first call `useDeleteOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderMutation, { data, loading, error }] = useDeleteOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrderMutation, DeleteOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrderMutation, DeleteOrderMutationVariables>(DeleteOrderDocument, options);
      }
export type DeleteOrderMutationHookResult = ReturnType<typeof useDeleteOrderMutation>;
export type DeleteOrderMutationResult = Apollo.MutationResult<DeleteOrderMutation>;
export type DeleteOrderMutationOptions = Apollo.BaseMutationOptions<DeleteOrderMutation, DeleteOrderMutationVariables>;
export const RefreshOrdersDocument = gql`
    mutation RefreshOrders {
  refreshStats {
    status
  }
}
    `;
export type RefreshOrdersMutationFn = Apollo.MutationFunction<RefreshOrdersMutation, RefreshOrdersMutationVariables>;

/**
 * __useRefreshOrdersMutation__
 *
 * To run a mutation, you first call `useRefreshOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshOrdersMutation, { data, loading, error }] = useRefreshOrdersMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshOrdersMutation(baseOptions?: Apollo.MutationHookOptions<RefreshOrdersMutation, RefreshOrdersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshOrdersMutation, RefreshOrdersMutationVariables>(RefreshOrdersDocument, options);
      }
export type RefreshOrdersMutationHookResult = ReturnType<typeof useRefreshOrdersMutation>;
export type RefreshOrdersMutationResult = Apollo.MutationResult<RefreshOrdersMutation>;
export type RefreshOrdersMutationOptions = Apollo.BaseMutationOptions<RefreshOrdersMutation, RefreshOrdersMutationVariables>;
export const UpdateOrderDocument = gql`
    mutation UpdateOrder($input: OrderInput!, $id: ID!) {
  updateOrder(input: $input, id: $id) {
    externalId
    status
    orderCreatedAt
  }
}
    `;
export type UpdateOrderMutationFn = Apollo.MutationFunction<UpdateOrderMutation, UpdateOrderMutationVariables>;

/**
 * __useUpdateOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderMutation, UpdateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(UpdateOrderDocument, options);
      }
export type UpdateOrderMutationHookResult = ReturnType<typeof useUpdateOrderMutation>;
export type UpdateOrderMutationResult = Apollo.MutationResult<UpdateOrderMutation>;
export type UpdateOrderMutationOptions = Apollo.BaseMutationOptions<UpdateOrderMutation, UpdateOrderMutationVariables>;
export const UpdateOrdersDocument = gql`
    mutation UpdateOrders($input: BulkUpdateOrderInput!, $ids: [ID!]!) {
  updateOrders(input: $input, ids: $ids) {
    id
    externalId
  }
}
    `;
export type UpdateOrdersMutationFn = Apollo.MutationFunction<UpdateOrdersMutation, UpdateOrdersMutationVariables>;

/**
 * __useUpdateOrdersMutation__
 *
 * To run a mutation, you first call `useUpdateOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrdersMutation, { data, loading, error }] = useUpdateOrdersMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useUpdateOrdersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrdersMutation, UpdateOrdersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrdersMutation, UpdateOrdersMutationVariables>(UpdateOrdersDocument, options);
      }
export type UpdateOrdersMutationHookResult = ReturnType<typeof useUpdateOrdersMutation>;
export type UpdateOrdersMutationResult = Apollo.MutationResult<UpdateOrdersMutation>;
export type UpdateOrdersMutationOptions = Apollo.BaseMutationOptions<UpdateOrdersMutation, UpdateOrdersMutationVariables>;
export const OrderDocument = gql`
    query Order($id: ID!) {
  order(id: $id) {
    ...OrderProps
  }
}
    ${OrderPropsFragmentDoc}`;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderQuery(baseOptions: Apollo.QueryHookOptions<OrderQuery, OrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
      }
export function useOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
        }
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderQueryResult = Apollo.QueryResult<OrderQuery, OrderQueryVariables>;
export const OrderByShippingNumberDocument = gql`
    query OrderByShippingNumber($shippingNumber: String!) {
  orderByShippingNumber(shippingNumber: $shippingNumber) {
    ...OrderProps
  }
}
    ${OrderPropsFragmentDoc}`;

/**
 * __useOrderByShippingNumberQuery__
 *
 * To run a query within a React component, call `useOrderByShippingNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderByShippingNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderByShippingNumberQuery({
 *   variables: {
 *      shippingNumber: // value for 'shippingNumber'
 *   },
 * });
 */
export function useOrderByShippingNumberQuery(baseOptions: Apollo.QueryHookOptions<OrderByShippingNumberQuery, OrderByShippingNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderByShippingNumberQuery, OrderByShippingNumberQueryVariables>(OrderByShippingNumberDocument, options);
      }
export function useOrderByShippingNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderByShippingNumberQuery, OrderByShippingNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderByShippingNumberQuery, OrderByShippingNumberQueryVariables>(OrderByShippingNumberDocument, options);
        }
export type OrderByShippingNumberQueryHookResult = ReturnType<typeof useOrderByShippingNumberQuery>;
export type OrderByShippingNumberLazyQueryHookResult = ReturnType<typeof useOrderByShippingNumberLazyQuery>;
export type OrderByShippingNumberQueryResult = Apollo.QueryResult<OrderByShippingNumberQuery, OrderByShippingNumberQueryVariables>;
export const OrdersDocument = gql`
    query Orders($page: Int, $first: Int = 15, $filter: OrderFilter, $sort: OrderSort) {
  orders(page: $page, first: $first, filter: $filter, sort: $sort) {
    paginatorInfo {
      ...Pagination
    }
    data {
      id
      externalId
      customer {
        name
      }
      marketplace {
        name
        slug
        currency {
          slug
          prefix
          suffix
        }
      }
      isPaid
      isInvoiced
      status
      orderCreatedAt
      orderTotal
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useOrdersQuery(baseOptions?: Apollo.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
      }
export function useOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = Apollo.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const CommentDocument = gql`
    mutation Comment($input: CommentInput!) {
  createComment(input: $input) {
    body
  }
}
    `;
export type CommentMutationFn = Apollo.MutationFunction<CommentMutation, CommentMutationVariables>;

/**
 * __useCommentMutation__
 *
 * To run a mutation, you first call `useCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commentMutation, { data, loading, error }] = useCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCommentMutation(baseOptions?: Apollo.MutationHookOptions<CommentMutation, CommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CommentMutation, CommentMutationVariables>(CommentDocument, options);
      }
export type CommentMutationHookResult = ReturnType<typeof useCommentMutation>;
export type CommentMutationResult = Apollo.MutationResult<CommentMutation>;
export type CommentMutationOptions = Apollo.BaseMutationOptions<CommentMutation, CommentMutationVariables>;
export const CreatePostDocument = gql`
    mutation CreatePost($input: PostInput!) {
  createPost(input: $input) {
    id
    body
  }
}
    `;
export type CreatePostMutationFn = Apollo.MutationFunction<CreatePostMutation, CreatePostMutationVariables>;

/**
 * __useCreatePostMutation__
 *
 * To run a mutation, you first call `useCreatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostMutation, { data, loading, error }] = useCreatePostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePostMutation(baseOptions?: Apollo.MutationHookOptions<CreatePostMutation, CreatePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePostMutation, CreatePostMutationVariables>(CreatePostDocument, options);
      }
export type CreatePostMutationHookResult = ReturnType<typeof useCreatePostMutation>;
export type CreatePostMutationResult = Apollo.MutationResult<CreatePostMutation>;
export type CreatePostMutationOptions = Apollo.BaseMutationOptions<CreatePostMutation, CreatePostMutationVariables>;
export const DeletePostDocument = gql`
    mutation DeletePost($id: ID!) {
  deletePost(id: $id) {
    id
  }
}
    `;
export type DeletePostMutationFn = Apollo.MutationFunction<DeletePostMutation, DeletePostMutationVariables>;

/**
 * __useDeletePostMutation__
 *
 * To run a mutation, you first call `useDeletePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostMutation, { data, loading, error }] = useDeletePostMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePostMutation(baseOptions?: Apollo.MutationHookOptions<DeletePostMutation, DeletePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePostMutation, DeletePostMutationVariables>(DeletePostDocument, options);
      }
export type DeletePostMutationHookResult = ReturnType<typeof useDeletePostMutation>;
export type DeletePostMutationResult = Apollo.MutationResult<DeletePostMutation>;
export type DeletePostMutationOptions = Apollo.BaseMutationOptions<DeletePostMutation, DeletePostMutationVariables>;
export const LikeDocument = gql`
    mutation Like($input: LikeInput!) {
  createLike(input: $input) {
    id
  }
}
    `;
export type LikeMutationFn = Apollo.MutationFunction<LikeMutation, LikeMutationVariables>;

/**
 * __useLikeMutation__
 *
 * To run a mutation, you first call `useLikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeMutation, { data, loading, error }] = useLikeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLikeMutation(baseOptions?: Apollo.MutationHookOptions<LikeMutation, LikeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LikeMutation, LikeMutationVariables>(LikeDocument, options);
      }
export type LikeMutationHookResult = ReturnType<typeof useLikeMutation>;
export type LikeMutationResult = Apollo.MutationResult<LikeMutation>;
export type LikeMutationOptions = Apollo.BaseMutationOptions<LikeMutation, LikeMutationVariables>;
export const UnlikeDocument = gql`
    mutation Unlike($input: LikeInput!) {
  deleteLike(input: $input) {
    id
  }
}
    `;
export type UnlikeMutationFn = Apollo.MutationFunction<UnlikeMutation, UnlikeMutationVariables>;

/**
 * __useUnlikeMutation__
 *
 * To run a mutation, you first call `useUnlikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlikeMutation, { data, loading, error }] = useUnlikeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnlikeMutation(baseOptions?: Apollo.MutationHookOptions<UnlikeMutation, UnlikeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlikeMutation, UnlikeMutationVariables>(UnlikeDocument, options);
      }
export type UnlikeMutationHookResult = ReturnType<typeof useUnlikeMutation>;
export type UnlikeMutationResult = Apollo.MutationResult<UnlikeMutation>;
export type UnlikeMutationOptions = Apollo.BaseMutationOptions<UnlikeMutation, UnlikeMutationVariables>;
export const PostsDocument = gql`
    query Posts($first: Int = 15, $page: Int) {
  posts(first: $first, page: $page) {
    data {
      id
      user {
        id
        name
        image {
          url
        }
      }
      isLiked
      likes {
        id
        user {
          name
        }
      }
      body
      image {
        url
      }
      comments {
        id
        user {
          name
          image {
            url
          }
        }
        isLiked
        image {
          url
        }
        body
        createdAt
        likes {
          id
          user {
            name
          }
        }
      }
      createdAt
    }
  }
}
    `;

/**
 * __usePostsQuery__
 *
 * To run a query within a React component, call `usePostsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePostsQuery(baseOptions?: Apollo.QueryHookOptions<PostsQuery, PostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostsQuery, PostsQueryVariables>(PostsDocument, options);
      }
export function usePostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostsQuery, PostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostsQuery, PostsQueryVariables>(PostsDocument, options);
        }
export type PostsQueryHookResult = ReturnType<typeof usePostsQuery>;
export type PostsLazyQueryHookResult = ReturnType<typeof usePostsLazyQuery>;
export type PostsQueryResult = Apollo.QueryResult<PostsQuery, PostsQueryVariables>;
export const DeleteProductDocument = gql`
    mutation DeleteProduct($id: ID!) {
  deleteProduct(id: $id) {
    name
  }
}
    `;
export type DeleteProductMutationFn = Apollo.MutationFunction<DeleteProductMutation, DeleteProductMutationVariables>;

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductMutation, DeleteProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, options);
      }
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>;
export type DeleteProductMutationResult = Apollo.MutationResult<DeleteProductMutation>;
export type DeleteProductMutationOptions = Apollo.BaseMutationOptions<DeleteProductMutation, DeleteProductMutationVariables>;
export const DeleteProductsDocument = gql`
    mutation DeleteProducts($ids: [ID!]!) {
  deleteProducts(ids: $ids) {
    name
  }
}
    `;
export type DeleteProductsMutationFn = Apollo.MutationFunction<DeleteProductsMutation, DeleteProductsMutationVariables>;

/**
 * __useDeleteProductsMutation__
 *
 * To run a mutation, you first call `useDeleteProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductsMutation, { data, loading, error }] = useDeleteProductsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteProductsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductsMutation, DeleteProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductsMutation, DeleteProductsMutationVariables>(DeleteProductsDocument, options);
      }
export type DeleteProductsMutationHookResult = ReturnType<typeof useDeleteProductsMutation>;
export type DeleteProductsMutationResult = Apollo.MutationResult<DeleteProductsMutation>;
export type DeleteProductsMutationOptions = Apollo.BaseMutationOptions<DeleteProductsMutation, DeleteProductsMutationVariables>;
export const CreateImportDocument = gql`
    mutation CreateImport($input: CreateImportInput!) {
  createImport(input: $input) {
    id
    supplier {
      id
    }
    uploadFileUrl
    status
    priceMultiplier
    currencyMultiplier
    vat
    marketplace {
      slug
      name
      currency {
        slug
        prefix
        suffix
      }
    }
    properties {
      from
      to
    }
  }
}
    `;
export type CreateImportMutationFn = Apollo.MutationFunction<CreateImportMutation, CreateImportMutationVariables>;

/**
 * __useCreateImportMutation__
 *
 * To run a mutation, you first call `useCreateImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createImportMutation, { data, loading, error }] = useCreateImportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateImportMutation(baseOptions?: Apollo.MutationHookOptions<CreateImportMutation, CreateImportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateImportMutation, CreateImportMutationVariables>(CreateImportDocument, options);
      }
export type CreateImportMutationHookResult = ReturnType<typeof useCreateImportMutation>;
export type CreateImportMutationResult = Apollo.MutationResult<CreateImportMutation>;
export type CreateImportMutationOptions = Apollo.BaseMutationOptions<CreateImportMutation, CreateImportMutationVariables>;
export const StartImportDocument = gql`
    mutation StartImport($input: StartImportInput!, $id: ID!) {
  startImport(input: $input, id: $id) {
    status
    message
  }
}
    `;
export type StartImportMutationFn = Apollo.MutationFunction<StartImportMutation, StartImportMutationVariables>;

/**
 * __useStartImportMutation__
 *
 * To run a mutation, you first call `useStartImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startImportMutation, { data, loading, error }] = useStartImportMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStartImportMutation(baseOptions?: Apollo.MutationHookOptions<StartImportMutation, StartImportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartImportMutation, StartImportMutationVariables>(StartImportDocument, options);
      }
export type StartImportMutationHookResult = ReturnType<typeof useStartImportMutation>;
export type StartImportMutationResult = Apollo.MutationResult<StartImportMutation>;
export type StartImportMutationOptions = Apollo.BaseMutationOptions<StartImportMutation, StartImportMutationVariables>;
export const GenerateFileForImportDocument = gql`
    mutation GenerateFileForImport($input: GenerateFileForImportInput!, $id: ID!) {
  generateFileForImport(input: $input, id: $id) {
    status
    message
  }
}
    `;
export type GenerateFileForImportMutationFn = Apollo.MutationFunction<GenerateFileForImportMutation, GenerateFileForImportMutationVariables>;

/**
 * __useGenerateFileForImportMutation__
 *
 * To run a mutation, you first call `useGenerateFileForImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateFileForImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateFileForImportMutation, { data, loading, error }] = useGenerateFileForImportMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGenerateFileForImportMutation(baseOptions?: Apollo.MutationHookOptions<GenerateFileForImportMutation, GenerateFileForImportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateFileForImportMutation, GenerateFileForImportMutationVariables>(GenerateFileForImportDocument, options);
      }
export type GenerateFileForImportMutationHookResult = ReturnType<typeof useGenerateFileForImportMutation>;
export type GenerateFileForImportMutationResult = Apollo.MutationResult<GenerateFileForImportMutation>;
export type GenerateFileForImportMutationOptions = Apollo.BaseMutationOptions<GenerateFileForImportMutation, GenerateFileForImportMutationVariables>;
export const StartPlainImportDocument = gql`
    mutation StartPlainImport($input: StartPlainImportInput!) {
  startPlainImport(input: $input) {
    status
    message
  }
}
    `;
export type StartPlainImportMutationFn = Apollo.MutationFunction<StartPlainImportMutation, StartPlainImportMutationVariables>;

/**
 * __useStartPlainImportMutation__
 *
 * To run a mutation, you first call `useStartPlainImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartPlainImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startPlainImportMutation, { data, loading, error }] = useStartPlainImportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartPlainImportMutation(baseOptions?: Apollo.MutationHookOptions<StartPlainImportMutation, StartPlainImportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartPlainImportMutation, StartPlainImportMutationVariables>(StartPlainImportDocument, options);
      }
export type StartPlainImportMutationHookResult = ReturnType<typeof useStartPlainImportMutation>;
export type StartPlainImportMutationResult = Apollo.MutationResult<StartPlainImportMutation>;
export type StartPlainImportMutationOptions = Apollo.BaseMutationOptions<StartPlainImportMutation, StartPlainImportMutationVariables>;
export const UpdateProductDocument = gql`
    mutation UpdateProduct($input: ProductInput!, $id: ID!) {
  updateProduct(input: $input, id: $id) {
    name
  }
}
    `;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, options);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const ImportsDocument = gql`
    query Imports($first: Int = 15, $page: Int) {
  imports(first: $first, page: $page) {
    paginatorInfo {
      ...Pagination
    }
    data {
      id
      supplier {
        name
      }
      uploadFileUrl
      lang
      status
      priceMultiplier
      vat
      marketplace {
        name
      }
      filtered
      productCount
      duplicationCount
      currencyMultiplier
      updatedCount
      eanMissingCount
      deactivatedCount
      marketplaceResponse
      allCount
      errorMessage
      createdAt
      newMissedProductsUrl
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useImportsQuery__
 *
 * To run a query within a React component, call `useImportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useImportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImportsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useImportsQuery(baseOptions?: Apollo.QueryHookOptions<ImportsQuery, ImportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ImportsQuery, ImportsQueryVariables>(ImportsDocument, options);
      }
export function useImportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ImportsQuery, ImportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ImportsQuery, ImportsQueryVariables>(ImportsDocument, options);
        }
export type ImportsQueryHookResult = ReturnType<typeof useImportsQuery>;
export type ImportsLazyQueryHookResult = ReturnType<typeof useImportsLazyQuery>;
export type ImportsQueryResult = Apollo.QueryResult<ImportsQuery, ImportsQueryVariables>;
export const ShowImportPropertiesDocument = gql`
    query ShowImportProperties($id: ID!) {
  showImportProperties(id: $id) {
    key
    readable
    optional
  }
}
    `;

/**
 * __useShowImportPropertiesQuery__
 *
 * To run a query within a React component, call `useShowImportPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowImportPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowImportPropertiesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShowImportPropertiesQuery(baseOptions: Apollo.QueryHookOptions<ShowImportPropertiesQuery, ShowImportPropertiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShowImportPropertiesQuery, ShowImportPropertiesQueryVariables>(ShowImportPropertiesDocument, options);
      }
export function useShowImportPropertiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShowImportPropertiesQuery, ShowImportPropertiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShowImportPropertiesQuery, ShowImportPropertiesQueryVariables>(ShowImportPropertiesDocument, options);
        }
export type ShowImportPropertiesQueryHookResult = ReturnType<typeof useShowImportPropertiesQuery>;
export type ShowImportPropertiesLazyQueryHookResult = ReturnType<typeof useShowImportPropertiesLazyQuery>;
export type ShowImportPropertiesQueryResult = Apollo.QueryResult<ShowImportPropertiesQuery, ShowImportPropertiesQueryVariables>;
export const ShowImportDocument = gql`
    query ShowImport($id: ID) {
  showImport(id: $id) {
    id
    uploadFileUrl
    status
    productCount
    duplicationCount
    updatedCount
    eanMissingCount
    deactivatedCount
    marketplaceResponse
    allCount
    errorMessage
    filtered
    lang
    createdAt
  }
}
    `;

/**
 * __useShowImportQuery__
 *
 * To run a query within a React component, call `useShowImportQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowImportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowImportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShowImportQuery(baseOptions?: Apollo.QueryHookOptions<ShowImportQuery, ShowImportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShowImportQuery, ShowImportQueryVariables>(ShowImportDocument, options);
      }
export function useShowImportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShowImportQuery, ShowImportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShowImportQuery, ShowImportQueryVariables>(ShowImportDocument, options);
        }
export type ShowImportQueryHookResult = ReturnType<typeof useShowImportQuery>;
export type ShowImportLazyQueryHookResult = ReturnType<typeof useShowImportLazyQuery>;
export type ShowImportQueryResult = Apollo.QueryResult<ShowImportQuery, ShowImportQueryVariables>;
export const MarketplacesDocument = gql`
    query Marketplaces {
  marketplaces {
    slug
    name
    currency {
      slug
      prefix
      suffix
    }
  }
}
    `;

/**
 * __useMarketplacesQuery__
 *
 * To run a query within a React component, call `useMarketplacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplacesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMarketplacesQuery(baseOptions?: Apollo.QueryHookOptions<MarketplacesQuery, MarketplacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketplacesQuery, MarketplacesQueryVariables>(MarketplacesDocument, options);
      }
export function useMarketplacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplacesQuery, MarketplacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketplacesQuery, MarketplacesQueryVariables>(MarketplacesDocument, options);
        }
export type MarketplacesQueryHookResult = ReturnType<typeof useMarketplacesQuery>;
export type MarketplacesLazyQueryHookResult = ReturnType<typeof useMarketplacesLazyQuery>;
export type MarketplacesQueryResult = Apollo.QueryResult<MarketplacesQuery, MarketplacesQueryVariables>;
export const ProductDocument = gql`
    query Product($id: ID!) {
  product(id: $id) {
    id
    ean
    name
    pictureUrl
    discount
    country
    shortDescription
    description
    price
    minPrice
    minDeliveryTime
    maxDeliveryTime
    category
    marketplace {
      currency {
        suffix
        prefix
      }
    }
  }
}
    `;

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductQuery(baseOptions: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
      }
export function useProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
        }
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<ProductQuery, ProductQueryVariables>;
export const ProductCategoriesDocument = gql`
    query ProductCategories($searchTerm: String!) {
  productCategories(name: $searchTerm)
}
    `;

/**
 * __useProductCategoriesQuery__
 *
 * To run a query within a React component, call `useProductCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCategoriesQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useProductCategoriesQuery(baseOptions: Apollo.QueryHookOptions<ProductCategoriesQuery, ProductCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductCategoriesQuery, ProductCategoriesQueryVariables>(ProductCategoriesDocument, options);
      }
export function useProductCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductCategoriesQuery, ProductCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductCategoriesQuery, ProductCategoriesQueryVariables>(ProductCategoriesDocument, options);
        }
export type ProductCategoriesQueryHookResult = ReturnType<typeof useProductCategoriesQuery>;
export type ProductCategoriesLazyQueryHookResult = ReturnType<typeof useProductCategoriesLazyQuery>;
export type ProductCategoriesQueryResult = Apollo.QueryResult<ProductCategoriesQuery, ProductCategoriesQueryVariables>;
export const ProductsDocument = gql`
    query Products($slug: MarketplaceSlug!, $first: Int = 15, $page: Int, $filter: ProductFilter) {
  products(marketplace: $slug, first: $first, page: $page, filter: $filter) {
    paginatorInfo {
      ...Pagination
    }
    data {
      id
      createdAt
      ean
      name
      category
      price
      minPrice
      minDeliveryTime
      maxDeliveryTime
      description
      marketplace {
        name
        currency {
          prefix
          suffix
        }
      }
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      first: // value for 'first'
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProductsQuery(baseOptions: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
      }
export function useProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const InitialStatsDocument = gql`
    query InitialStats {
  ordersCountCurrentStat {
    ...ChartData
  }
  revenueCurrentStat {
    ...ChartData
  }
  revenueYearlyStat {
    ...CurrentAndPrevious
  }
}
    ${ChartDataFragmentDoc}
${CurrentAndPreviousFragmentDoc}`;

/**
 * __useInitialStatsQuery__
 *
 * To run a query within a React component, call `useInitialStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitialStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitialStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useInitialStatsQuery(baseOptions?: Apollo.QueryHookOptions<InitialStatsQuery, InitialStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InitialStatsQuery, InitialStatsQueryVariables>(InitialStatsDocument, options);
      }
export function useInitialStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InitialStatsQuery, InitialStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InitialStatsQuery, InitialStatsQueryVariables>(InitialStatsDocument, options);
        }
export type InitialStatsQueryHookResult = ReturnType<typeof useInitialStatsQuery>;
export type InitialStatsLazyQueryHookResult = ReturnType<typeof useInitialStatsLazyQuery>;
export type InitialStatsQueryResult = Apollo.QueryResult<InitialStatsQuery, InitialStatsQueryVariables>;
export const RevenueYearlyStatDocument = gql`
    query RevenueYearlyStat($year: Int) {
  revenueYearlyStat(year: $year) {
    ...CurrentAndPrevious
  }
}
    ${CurrentAndPreviousFragmentDoc}`;

/**
 * __useRevenueYearlyStatQuery__
 *
 * To run a query within a React component, call `useRevenueYearlyStatQuery` and pass it any options that fit your needs.
 * When your component renders, `useRevenueYearlyStatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRevenueYearlyStatQuery({
 *   variables: {
 *      year: // value for 'year'
 *   },
 * });
 */
export function useRevenueYearlyStatQuery(baseOptions?: Apollo.QueryHookOptions<RevenueYearlyStatQuery, RevenueYearlyStatQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RevenueYearlyStatQuery, RevenueYearlyStatQueryVariables>(RevenueYearlyStatDocument, options);
      }
export function useRevenueYearlyStatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RevenueYearlyStatQuery, RevenueYearlyStatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RevenueYearlyStatQuery, RevenueYearlyStatQueryVariables>(RevenueYearlyStatDocument, options);
        }
export type RevenueYearlyStatQueryHookResult = ReturnType<typeof useRevenueYearlyStatQuery>;
export type RevenueYearlyStatLazyQueryHookResult = ReturnType<typeof useRevenueYearlyStatLazyQuery>;
export type RevenueYearlyStatQueryResult = Apollo.QueryResult<RevenueYearlyStatQuery, RevenueYearlyStatQueryVariables>;
export const RevenueMonthlyStatDocument = gql`
    query RevenueMonthlyStat($year: Int, $month: Int) {
  revenueMonthlyStat(year: $year, month: $month) {
    ...CurrentAndPrevious
  }
}
    ${CurrentAndPreviousFragmentDoc}`;

/**
 * __useRevenueMonthlyStatQuery__
 *
 * To run a query within a React component, call `useRevenueMonthlyStatQuery` and pass it any options that fit your needs.
 * When your component renders, `useRevenueMonthlyStatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRevenueMonthlyStatQuery({
 *   variables: {
 *      year: // value for 'year'
 *      month: // value for 'month'
 *   },
 * });
 */
export function useRevenueMonthlyStatQuery(baseOptions?: Apollo.QueryHookOptions<RevenueMonthlyStatQuery, RevenueMonthlyStatQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RevenueMonthlyStatQuery, RevenueMonthlyStatQueryVariables>(RevenueMonthlyStatDocument, options);
      }
export function useRevenueMonthlyStatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RevenueMonthlyStatQuery, RevenueMonthlyStatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RevenueMonthlyStatQuery, RevenueMonthlyStatQueryVariables>(RevenueMonthlyStatDocument, options);
        }
export type RevenueMonthlyStatQueryHookResult = ReturnType<typeof useRevenueMonthlyStatQuery>;
export type RevenueMonthlyStatLazyQueryHookResult = ReturnType<typeof useRevenueMonthlyStatLazyQuery>;
export type RevenueMonthlyStatQueryResult = Apollo.QueryResult<RevenueMonthlyStatQuery, RevenueMonthlyStatQueryVariables>;
export const RevenueWeeklyStatDocument = gql`
    query RevenueWeeklyStat($year: Int, $month: Int, $day: Int) {
  revenueWeeklyStat(year: $year, month: $month, day: $day) {
    ...CurrentAndPrevious
  }
}
    ${CurrentAndPreviousFragmentDoc}`;

/**
 * __useRevenueWeeklyStatQuery__
 *
 * To run a query within a React component, call `useRevenueWeeklyStatQuery` and pass it any options that fit your needs.
 * When your component renders, `useRevenueWeeklyStatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRevenueWeeklyStatQuery({
 *   variables: {
 *      year: // value for 'year'
 *      month: // value for 'month'
 *      day: // value for 'day'
 *   },
 * });
 */
export function useRevenueWeeklyStatQuery(baseOptions?: Apollo.QueryHookOptions<RevenueWeeklyStatQuery, RevenueWeeklyStatQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RevenueWeeklyStatQuery, RevenueWeeklyStatQueryVariables>(RevenueWeeklyStatDocument, options);
      }
export function useRevenueWeeklyStatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RevenueWeeklyStatQuery, RevenueWeeklyStatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RevenueWeeklyStatQuery, RevenueWeeklyStatQueryVariables>(RevenueWeeklyStatDocument, options);
        }
export type RevenueWeeklyStatQueryHookResult = ReturnType<typeof useRevenueWeeklyStatQuery>;
export type RevenueWeeklyStatLazyQueryHookResult = ReturnType<typeof useRevenueWeeklyStatLazyQuery>;
export type RevenueWeeklyStatQueryResult = Apollo.QueryResult<RevenueWeeklyStatQuery, RevenueWeeklyStatQueryVariables>;
export const AddOrderItemToStockDocument = gql`
    mutation AddOrderItemToStock($input: OrderItemToStockInput!, $id: ID!) {
  addOrderItemToStock(input: $input, id: $id) {
    name
  }
}
    `;
export type AddOrderItemToStockMutationFn = Apollo.MutationFunction<AddOrderItemToStockMutation, AddOrderItemToStockMutationVariables>;

/**
 * __useAddOrderItemToStockMutation__
 *
 * To run a mutation, you first call `useAddOrderItemToStockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrderItemToStockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrderItemToStockMutation, { data, loading, error }] = useAddOrderItemToStockMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAddOrderItemToStockMutation(baseOptions?: Apollo.MutationHookOptions<AddOrderItemToStockMutation, AddOrderItemToStockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrderItemToStockMutation, AddOrderItemToStockMutationVariables>(AddOrderItemToStockDocument, options);
      }
export type AddOrderItemToStockMutationHookResult = ReturnType<typeof useAddOrderItemToStockMutation>;
export type AddOrderItemToStockMutationResult = Apollo.MutationResult<AddOrderItemToStockMutation>;
export type AddOrderItemToStockMutationOptions = Apollo.BaseMutationOptions<AddOrderItemToStockMutation, AddOrderItemToStockMutationVariables>;
export const CreateStockDocument = gql`
    mutation CreateStock($input: CreateStockInput!) {
  createStocks(input: $input)
}
    `;
export type CreateStockMutationFn = Apollo.MutationFunction<CreateStockMutation, CreateStockMutationVariables>;

/**
 * __useCreateStockMutation__
 *
 * To run a mutation, you first call `useCreateStockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockMutation, { data, loading, error }] = useCreateStockMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStockMutation(baseOptions?: Apollo.MutationHookOptions<CreateStockMutation, CreateStockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStockMutation, CreateStockMutationVariables>(CreateStockDocument, options);
      }
export type CreateStockMutationHookResult = ReturnType<typeof useCreateStockMutation>;
export type CreateStockMutationResult = Apollo.MutationResult<CreateStockMutation>;
export type CreateStockMutationOptions = Apollo.BaseMutationOptions<CreateStockMutation, CreateStockMutationVariables>;
export const DeleteStockDocument = gql`
    mutation DeleteStock($ean: String!) {
  deleteStock(ean: $ean)
}
    `;
export type DeleteStockMutationFn = Apollo.MutationFunction<DeleteStockMutation, DeleteStockMutationVariables>;

/**
 * __useDeleteStockMutation__
 *
 * To run a mutation, you first call `useDeleteStockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStockMutation, { data, loading, error }] = useDeleteStockMutation({
 *   variables: {
 *      ean: // value for 'ean'
 *   },
 * });
 */
export function useDeleteStockMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStockMutation, DeleteStockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStockMutation, DeleteStockMutationVariables>(DeleteStockDocument, options);
      }
export type DeleteStockMutationHookResult = ReturnType<typeof useDeleteStockMutation>;
export type DeleteStockMutationResult = Apollo.MutationResult<DeleteStockMutation>;
export type DeleteStockMutationOptions = Apollo.BaseMutationOptions<DeleteStockMutation, DeleteStockMutationVariables>;
export const DeleteStocksDocument = gql`
    mutation DeleteStocks($eans: [String!]!) {
  deleteStocks(eans: $eans)
}
    `;
export type DeleteStocksMutationFn = Apollo.MutationFunction<DeleteStocksMutation, DeleteStocksMutationVariables>;

/**
 * __useDeleteStocksMutation__
 *
 * To run a mutation, you first call `useDeleteStocksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStocksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStocksMutation, { data, loading, error }] = useDeleteStocksMutation({
 *   variables: {
 *      eans: // value for 'eans'
 *   },
 * });
 */
export function useDeleteStocksMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStocksMutation, DeleteStocksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStocksMutation, DeleteStocksMutationVariables>(DeleteStocksDocument, options);
      }
export type DeleteStocksMutationHookResult = ReturnType<typeof useDeleteStocksMutation>;
export type DeleteStocksMutationResult = Apollo.MutationResult<DeleteStocksMutation>;
export type DeleteStocksMutationOptions = Apollo.BaseMutationOptions<DeleteStocksMutation, DeleteStocksMutationVariables>;
export const UpdateStockDocument = gql`
    mutation UpdateStock($ean: String!, $input: UpdateStockInput!) {
  updateStocks(input: $input, ean: $ean)
}
    `;
export type UpdateStockMutationFn = Apollo.MutationFunction<UpdateStockMutation, UpdateStockMutationVariables>;

/**
 * __useUpdateStockMutation__
 *
 * To run a mutation, you first call `useUpdateStockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStockMutation, { data, loading, error }] = useUpdateStockMutation({
 *   variables: {
 *      ean: // value for 'ean'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStockMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStockMutation, UpdateStockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStockMutation, UpdateStockMutationVariables>(UpdateStockDocument, options);
      }
export type UpdateStockMutationHookResult = ReturnType<typeof useUpdateStockMutation>;
export type UpdateStockMutationResult = Apollo.MutationResult<UpdateStockMutation>;
export type UpdateStockMutationOptions = Apollo.BaseMutationOptions<UpdateStockMutation, UpdateStockMutationVariables>;
export const StocksDocument = gql`
    query Stocks($filter: StockFilter, $first: Int = 15, $page: Int) {
  stocks(filter: $filter, first: $first, page: $page) {
    paginatorInfo {
      ...Pagination
    }
    data {
      ean
      names
      categories
      quantity
      lastUpdatedAt
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useStocksQuery__
 *
 * To run a query within a React component, call `useStocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useStocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStocksQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useStocksQuery(baseOptions?: Apollo.QueryHookOptions<StocksQuery, StocksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StocksQuery, StocksQueryVariables>(StocksDocument, options);
      }
export function useStocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StocksQuery, StocksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StocksQuery, StocksQueryVariables>(StocksDocument, options);
        }
export type StocksQueryHookResult = ReturnType<typeof useStocksQuery>;
export type StocksLazyQueryHookResult = ReturnType<typeof useStocksLazyQuery>;
export type StocksQueryResult = Apollo.QueryResult<StocksQuery, StocksQueryVariables>;
export const CreateSupplierDocument = gql`
    mutation CreateSupplier($input: CreateSupplierInput!) {
  createSupplier(input: $input) {
    ...SupplierProps
  }
}
    ${SupplierPropsFragmentDoc}`;
export type CreateSupplierMutationFn = Apollo.MutationFunction<CreateSupplierMutation, CreateSupplierMutationVariables>;

/**
 * __useCreateSupplierMutation__
 *
 * To run a mutation, you first call `useCreateSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierMutation, { data, loading, error }] = useCreateSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSupplierMutation(baseOptions?: Apollo.MutationHookOptions<CreateSupplierMutation, CreateSupplierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSupplierMutation, CreateSupplierMutationVariables>(CreateSupplierDocument, options);
      }
export type CreateSupplierMutationHookResult = ReturnType<typeof useCreateSupplierMutation>;
export type CreateSupplierMutationResult = Apollo.MutationResult<CreateSupplierMutation>;
export type CreateSupplierMutationOptions = Apollo.BaseMutationOptions<CreateSupplierMutation, CreateSupplierMutationVariables>;
export const DeleteSupplierDocument = gql`
    mutation DeleteSupplier($id: ID!) {
  deleteSupplier(id: $id) {
    name
  }
}
    `;
export type DeleteSupplierMutationFn = Apollo.MutationFunction<DeleteSupplierMutation, DeleteSupplierMutationVariables>;

/**
 * __useDeleteSupplierMutation__
 *
 * To run a mutation, you first call `useDeleteSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSupplierMutation, { data, loading, error }] = useDeleteSupplierMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSupplierMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSupplierMutation, DeleteSupplierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSupplierMutation, DeleteSupplierMutationVariables>(DeleteSupplierDocument, options);
      }
export type DeleteSupplierMutationHookResult = ReturnType<typeof useDeleteSupplierMutation>;
export type DeleteSupplierMutationResult = Apollo.MutationResult<DeleteSupplierMutation>;
export type DeleteSupplierMutationOptions = Apollo.BaseMutationOptions<DeleteSupplierMutation, DeleteSupplierMutationVariables>;
export const UpdateSupplierDocument = gql`
    mutation UpdateSupplier($input: UpdateSupplierInput!, $id: ID!) {
  updateSupplier(input: $input, id: $id) {
    name
  }
}
    `;
export type UpdateSupplierMutationFn = Apollo.MutationFunction<UpdateSupplierMutation, UpdateSupplierMutationVariables>;

/**
 * __useUpdateSupplierMutation__
 *
 * To run a mutation, you first call `useUpdateSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierMutation, { data, loading, error }] = useUpdateSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateSupplierMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSupplierMutation, UpdateSupplierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSupplierMutation, UpdateSupplierMutationVariables>(UpdateSupplierDocument, options);
      }
export type UpdateSupplierMutationHookResult = ReturnType<typeof useUpdateSupplierMutation>;
export type UpdateSupplierMutationResult = Apollo.MutationResult<UpdateSupplierMutation>;
export type UpdateSupplierMutationOptions = Apollo.BaseMutationOptions<UpdateSupplierMutation, UpdateSupplierMutationVariables>;
export const UpdateSupplierConfigurationDocument = gql`
    mutation UpdateSupplierConfiguration($input: SupplierConfigurationInput!, $supplierId: ID!, $marketplace: MarketplaceSlug!) {
  updateSupplierConfiguration(
    input: $input
    supplierId: $supplierId
    marketplace: $marketplace
  ) {
    ...SupplierProps
  }
}
    ${SupplierPropsFragmentDoc}`;
export type UpdateSupplierConfigurationMutationFn = Apollo.MutationFunction<UpdateSupplierConfigurationMutation, UpdateSupplierConfigurationMutationVariables>;

/**
 * __useUpdateSupplierConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateSupplierConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierConfigurationMutation, { data, loading, error }] = useUpdateSupplierConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      supplierId: // value for 'supplierId'
 *      marketplace: // value for 'marketplace'
 *   },
 * });
 */
export function useUpdateSupplierConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSupplierConfigurationMutation, UpdateSupplierConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSupplierConfigurationMutation, UpdateSupplierConfigurationMutationVariables>(UpdateSupplierConfigurationDocument, options);
      }
export type UpdateSupplierConfigurationMutationHookResult = ReturnType<typeof useUpdateSupplierConfigurationMutation>;
export type UpdateSupplierConfigurationMutationResult = Apollo.MutationResult<UpdateSupplierConfigurationMutation>;
export type UpdateSupplierConfigurationMutationOptions = Apollo.BaseMutationOptions<UpdateSupplierConfigurationMutation, UpdateSupplierConfigurationMutationVariables>;
export const MarketplacePropertiesDocument = gql`
    query marketplaceProperties($marketplace: MarketplaceSlug!) {
  showMarketplaceProperties(marketplace: $marketplace) {
    key
    readable
    optional
  }
}
    `;

/**
 * __useMarketplacePropertiesQuery__
 *
 * To run a query within a React component, call `useMarketplacePropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplacePropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplacePropertiesQuery({
 *   variables: {
 *      marketplace: // value for 'marketplace'
 *   },
 * });
 */
export function useMarketplacePropertiesQuery(baseOptions: Apollo.QueryHookOptions<MarketplacePropertiesQuery, MarketplacePropertiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketplacePropertiesQuery, MarketplacePropertiesQueryVariables>(MarketplacePropertiesDocument, options);
      }
export function useMarketplacePropertiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplacePropertiesQuery, MarketplacePropertiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketplacePropertiesQuery, MarketplacePropertiesQueryVariables>(MarketplacePropertiesDocument, options);
        }
export type MarketplacePropertiesQueryHookResult = ReturnType<typeof useMarketplacePropertiesQuery>;
export type MarketplacePropertiesLazyQueryHookResult = ReturnType<typeof useMarketplacePropertiesLazyQuery>;
export type MarketplacePropertiesQueryResult = Apollo.QueryResult<MarketplacePropertiesQuery, MarketplacePropertiesQueryVariables>;
export const SuppliersDocument = gql`
    query Suppliers {
  suppliers {
    ...SupplierProps
  }
}
    ${SupplierPropsFragmentDoc}`;

/**
 * __useSuppliersQuery__
 *
 * To run a query within a React component, call `useSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuppliersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSuppliersQuery(baseOptions?: Apollo.QueryHookOptions<SuppliersQuery, SuppliersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuppliersQuery, SuppliersQueryVariables>(SuppliersDocument, options);
      }
export function useSuppliersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuppliersQuery, SuppliersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuppliersQuery, SuppliersQueryVariables>(SuppliersDocument, options);
        }
export type SuppliersQueryHookResult = ReturnType<typeof useSuppliersQuery>;
export type SuppliersLazyQueryHookResult = ReturnType<typeof useSuppliersLazyQuery>;
export type SuppliersQueryResult = Apollo.QueryResult<SuppliersQuery, SuppliersQueryVariables>;
export const SupplierDocument = gql`
    query Supplier($id: ID!) {
  supplier(id: $id) {
    ...SupplierProps
  }
}
    ${SupplierPropsFragmentDoc}`;

/**
 * __useSupplierQuery__
 *
 * To run a query within a React component, call `useSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSupplierQuery(baseOptions: Apollo.QueryHookOptions<SupplierQuery, SupplierQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SupplierQuery, SupplierQueryVariables>(SupplierDocument, options);
      }
export function useSupplierLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupplierQuery, SupplierQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SupplierQuery, SupplierQueryVariables>(SupplierDocument, options);
        }
export type SupplierQueryHookResult = ReturnType<typeof useSupplierQuery>;
export type SupplierLazyQueryHookResult = ReturnType<typeof useSupplierLazyQuery>;
export type SupplierQueryResult = Apollo.QueryResult<SupplierQuery, SupplierQueryVariables>;