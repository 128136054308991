export type AuthAction =
  | 'LOGIN_BEGIN'
  | 'LOGIN_SUCCESS'
  | 'LOGIN_ERR'
  | 'LOGOUT_BEGIN'
  | 'LOGOUT_SUCCESS'
  | 'LOGOUT_ERR'

export type AuthActionFn = (payload?: string) => { type: AuthAction; data?: string; error?: string }

const actions: Record<string, AuthActionFn> = {
  loginBegin: () => {
    return {
      type: 'LOGIN_BEGIN',
    }
  },

  loginSuccess: data => {
    return {
      type: 'LOGIN_SUCCESS',
      data,
    }
  },

  loginErr: err => {
    return {
      type: 'LOGIN_ERR',
      err,
    }
  },

  logoutBegin: () => {
    return {
      type: 'LOGOUT_BEGIN',
    }
  },

  logoutSuccess: data => {
    return {
      type: 'LOGOUT_SUCCESS',
      data,
    }
  },

  logoutErr: error => {
    return {
      type: 'LOGOUT_ERR',
      error,
    }
  },
}

export default actions
