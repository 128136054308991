import React, { lazy } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

const Outgoing = lazy(() => import('../../container/invoices/outgoing'))
const Incoming = lazy(() => import('../../container/invoices/incoming'))

const OrdersRoutes = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${path}/outgoing`} component={Outgoing} />
      <Route exact path={`${path}/incoming`} component={Incoming} />
    </Switch>
  )
}

export default OrdersRoutes
