import React from 'react'
import { Avatar, Row } from 'antd'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import FeatherIcon from 'feather-icons-react'
import { InfoWraper, UserDropDwon } from './auth-info-style'
import Settings from './settings'
import { Popover } from '../../popup/popup'
import { logOut } from '../../../redux/authentication/actionCreator'
import Heading from '../../heading/heading'
import { useMeQuery } from '../../../graphql/generated/graphql'
import Spacing from '../../OrderChannel/Misc/Spacing'

const AuthInfo = () => {
  const dispatch = useDispatch()

  const { data: meData } = useMeQuery()

  const SignOut = (e: any) => {
    e.preventDefault()
    dispatch(logOut())
  }

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <Row wrap={true} align="middle">
          <Avatar src={meData?.me?.image?.url}>{meData?.me?.name?.[0]}</Avatar>
          <Spacing x={8} />
          <Heading className="m-0" as="h5">
            {meData?.me?.name}
          </Heading>
        </Row>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  )

  return (
    <InfoWraper>
      <Settings />

      <div>
        <Popover placement="bottomRight" content={userContent} trigger="click">
          <Link to="#" className="head-example">
            <Avatar src={meData?.me?.image?.url}>{meData?.me?.name[0]}</Avatar>
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  )
}

export default AuthInfo
