import { ApolloClient } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import Cookies from 'js-cookie'
import cache from './cache'
import { createUploadLink } from 'apollo-upload-client'

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL,
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('OCAccessToken') ?? Cookies.get('accessToken')
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const client = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
  name: 'Order channel API',
  defaultOptions: {
    mutate: {
      errorPolicy: 'all',
      refetchQueries: 'active',
    },
    query: {
      errorPolicy: 'all',
    },
  },
})

export default client
